import React, { useCallback, useEffect, useState } from "react";
import TimelineItem from "./TimelineItem";
import "../../styles/TimeLine/TimeLine.scss";

const Timeline = ({ activeTab, data,customerName, DSCombArrProp }) => {
  const timelineData = Array.isArray(data) ? data : [];
  const [openTimeline, setOpenTimeline] = useState(null);

  const closeModal = () => {
    // Implement the close modal logic here
    setOpenTimeline(null);
  };

  const renderData = useCallback(() => {
    const getBackgroundColor = () => {
      switch (activeTab) {
        case "Email":
          return "#BC7CD9";
        case "SMS":
          return "#0655B4";
        case "WhatsApp":
          return "#808080";
        case "Post":
          return "#808080";
        default:
          return "#BC7CD9"; // Default to Email color if active tab is not recognized
      }
    };

    return timelineData.map((data, idx) => (
      <TimelineItem
        customerName={customerName}
        data={data}
        key={idx}
        backgroundColor={getBackgroundColor()}
        closeModal={closeModal}
        isOpen={openTimeline === idx}
        onTimelineClick={() => setOpenTimeline(idx)}
        DSCombArrPassedProp={DSCombArrProp}
      />
    ));
  }, [timelineData, activeTab, closeModal, openTimeline, setOpenTimeline]);


  return (
    timelineData.length > 0 && (
      <div className="mainTimeLinewrapper">
        <div className="timelinewrapper">
          <div className="timeline-container">{renderData()}</div>
        </div>
        <div className="rightTimelinediv">
          <div className="timelineMode" style={{ backgroundColor: "#BC7CD9" }}>
            Email
          </div>
          <div className="timelineMode" style={{ backgroundColor: "#0655B4" }}>
            SMS
          </div>
          <div className="timelineMode" style={{ backgroundColor: "#D3D3D3" }}>
            WhatsApp
          </div>
          <div className="timelineMode" style={{ backgroundColor: "#D3D3D3" }}>
            Post
          </div>
        </div>
      </div>
    )
  );
};

export default Timeline;
