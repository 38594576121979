// API to Get Delivery Status of Communication Timeline Page which is shown as a green OR red e-mail icon based on `Undelivered` OR `Delivered` status of that message. It is in an Array of Objects Format
import Axios from "../../utils/axios";
import {API_URLS} from "../../utils/Constant";

export const getDeliveryStatuses=async () => {
  try {
    const response=await Axios.get(
      API_URLS.COMMUNICATION_TIMELINE.GET_DELIVERY_STATUSES,
    )
    const responseDataData=response?.data?.data;
    let DSPassArr = []
    let DSFailArr = []
    let DSCombArr = {}

    responseDataData.forEach((val) => {
      if (val?.successFlag === true) {
        DSPassArr.push(val?.deliveryStatus)
      } else if (val?.successFlag === false) {
        DSFailArr.push(val?.deliveryStatus)
      }
    })

    DSCombArr = {DSPassArr, DSFailArr}
    return DSCombArr
  } catch(error) {
    console.error("Error:",error);
    return null;
  }
}