import React from "react";
import Edit from '../images/pencil.png';

const EditButton =(props)=>{

    return(
        <button onClick={props.onClick} className="border-0 bg-body"><img src={Edit} alt="Edit"></img></button>
    )

}
export default EditButton;