import { createSlice } from "@reduxjs/toolkit";

export const LoginReducer = createSlice({
    name:"login",
    initialState:{
            loginData: {},
        token:" "
    },
    reducers:{
        storeLoginData:(state,action)=>{
            state.loginData=action.payload;
        },
        resetLoginData:(state,action)=>{
            state.loginData=action.payload;
        },
        storeToken:(state,action)=>{
            state.token=action.payload;
        },
        resetToken:(state,action)=>{
            state.token=action.payload;
        }
    }
})
export const {storeLoginData,resetLoginData,storeToken,resetToken} = LoginReducer.actions;
export default LoginReducer.reducer;