import { useEffect, useState } from "react";
import CommonTable from "../../common/Table";
import EditButton from "../../common/Editbutton";
import DeleteButton from "../../common/DeleteButton";
import moment from "moment/moment";
import { API_URLS } from "../../utils/Constant";
import axiosRole from '../../utils/axiosRole';
import CommonModal from "../../common/Modal";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router";
import { ROUTE_PATH } from "../../constants/Routes";
import Axios from "../../utils/axios";
import { useSelector } from "react-redux";

const UserListing = () => {
  const [UserData, setUserData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [userId, setUserID] = useState(null);
  const navigate=useNavigate();
  const selectLoginData = useSelector((state) => state?.login?.loginData);
  const permissionDTOList = selectLoginData?.permissionDTOList || [];
  const permission = permissionDTOList?.reduce((acc, permission) => {
    const { moduleId, ...rest } = permission;
    acc[moduleId] = rest;
    return acc;
  }, {});

  const Usercolumns = [
    {
      name: "User Name",
      selector: (row) => row.userName,
      minWidth: "150px",
    },
    {
      name: "Domain ID",
      selector: (row) => row.domainId,
      minWidth: "150px",
    },
    {
      name: "Email ID",
      selector: (row) => row.emailAddress,
      minWidth: "190px",
    },
    {
      name: "Contact No.",
      selector: (row) => row.contactNo,
      minWidth: "140px",
    },
    {
      name: "Added By",
      selector: (row) => row.createdBy,
      minWidth: "150px",
    },
    {
      name: "Added On",
      selector: (row) => row.createdAt,
      minWidth: "150px",
    }
  ];
  const actionColumn = {
    name: "Action",
    width: "90px",
    cell: (row) => (
      <div className="d-flex">
        {permission?.["3"]?.moduleEdit && (
          <EditButton
            onClick={() => {
              EditUser(row)
            }}
          />
        )}
        {permission?.["3"]?.moduleDelete && (
          <DeleteButton
            onClick={() => {
              DeleteUser(row?.id)
            }}
          />
        )}
      </div>
    ),
  };
  if(permission?.["3"]?.moduleEdit || permission?.["3"]?.moduleDelete ){
    Usercolumns.push(actionColumn);
  }
  const EditUser=(row)=>{
    navigate(ROUTE_PATH.ROLE_USER_EDIT_USER,{state:{USER_DATA:row}})
  }
  const UserListingData = (page) => {
  
    axiosRole.get(API_URLS.USER.USER_LIST + 10 + "/" + (page ? page : 0))
      .then((res) => {
        let result = res?.data?.data;
        result = result.map((item) => {
          let newItem = {
            id: item.userId,
            userName: item.userName,
            domainId: item.domainId,
            emailAddress: item.emailAddress,
            roleId: item.roleId,
            contactNo: item.contactNo,
            createdAt: moment(item.createdAt).format("YYYY-MM-DD"),
            createdBy: item.createdBy,
            description:item.description
          };
          return newItem;
        });
        setTotalRows(res?.totalCount);
        setUserData([...result]);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    UserListingData();
  }, []);
  const handlePageChange = (page) => {
    UserListingData((page - 1) * 10);
  };
  const DeleteUser = (userId) => {
    setShowConfirmationModal(true);
    setUserID(userId);
  };
  const handleDeleteUser = async (userId) => {
    setShowConfirmationModal(false);
    axiosRole.delete(API_URLS.USER.DELETE_USER + userId)
      .then((res) => {
        if(res.status === 200) {
          toast.info("User Deleted SuccessFully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 500,
          });
          
        }
        
      }).catch((error) => {
        toast.error("Issue while deleting User", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 500,
        });
      })
      .finally(() => {
        UserListingData();
        setShowConfirmationModal(false);
        
      });
  };
  return (
    <div className="">
      <CommonTable
        columns={Usercolumns}
        data={UserData}
        loading={loading}
        perPage={10}
        totalRows={totalRows}
        handlePageChange={handlePageChange}
      />
      <CommonModal
        show={showConfirmationModal}
        handleClose={() => setShowConfirmationModal(false)}
        handleConfirm={() => {
          handleDeleteUser(userId);
        }}
        message="are you sure you want to delete user ?"
      />
    </div>
  );
};
export default UserListing;
