import React, { useState } from "react";
import { ROUTE_PATH } from "../../constants/Routes";
import { Link, useNavigate } from "react-router-dom";
import "../../styles/header/header.scss";
import TATACAPITALIMG from "../../images/TATACapital.png";
import dropdown from "../../images/downArrow.png";
import { NavLink } from "react-router-dom/dist";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";

const Header = () => {
  const navigate = useNavigate();
  const selectLoginData = useSelector((state) => state.login.loginData);

  const permissionDTOList = selectLoginData?.permissionDTOList || [];

  const permission = permissionDTOList.reduce((acc, permission) => {
    const { moduleId, ...rest } = permission;
    acc[moduleId] = rest;
    return acc;
  }, {});
  const menuItem = [
    {
      path: ROUTE_PATH.COMMUNICATION_TIMELINE,
      name: "Communication Timeline",
      permission:
        permission?.["1"]?.moduleAll ||
        permission?.["1"]?.moduleDownload ||
        permission?.["1"]?.moduleView,
    },
    {
      path: ROUTE_PATH.STATISTICS,
      name: "Statistics",
      permission:
        permission?.["1"]?.moduleAll ||
        permission?.["1"]?.moduleDownload ||
        permission?.["1"]?.moduleView,
    },
    {
      path: ROUTE_PATH.ROLE_USER,
      name: "Role / User Management",
      permission:
        permission?.["2"]?.moduleCreate ||
        permission?.["2"]?.moduleAll ||
        permission?.["2"]?.moduleDownload ||
        permission?.["2"]?.moduleEdit ||
        permission?.["2"]?.moduleView ||
        permission?.["3"]?.moduledelete ||
        permission?.["3"]?.moduleCreate ||
        permission?.["3"]?.moduleAll ||
        permission?.["3"]?.moduleDownload ||
        permission?.["3"]?.moduleEdit ||
        permission?.["3"]?.moduleView ||
        permission?.["3"]?.moduledelete,
    },
  ];
 const Logout=()=>{
  sessionStorage.clear();
  navigate(ROUTE_PATH.LOGIN)
 }
  return (
    <>
      <nav class="navbar navbar-expand-lg  navbar-design header-container">
        <div class="container-fluid">
          <div className="left_logo ms-5">
            <img
              src={TATACAPITALIMG}
              alt="TATA CAPITAL"
              onClick={() => {
                navigate(ROUTE_PATH.COMMUNICATION_TIMELINE);
              }}
            ></img>
          </div>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0 navbar-design">
              {menuItem.map((item, index) => (
                <div key={index}>
                  {item.permission && (
                    <NavLink
                      to={item.path}
                      className="link ps-5"
                      activeclassname="active"
                    >
                     { item.name}
                    </NavLink>
                  )}
                </div>
              ))}
            </ul>
          </div>
          <div className="d-flex justify-content-end">
            <Button variant="warning" className="logout" onClick={Logout}>
              Logout
            </Button>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
