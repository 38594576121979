import { Button } from "react-bootstrap";
import "../../styles/RoleUser/createUser.scss";
import { ROUTE_PATH } from "../../constants/Routes";
import { useNavigate } from "react-router";
import SingleDropDown from "../../common/SingleDropdown";
import { useEffect, useState } from "react";
import axiosRole from '../../utils/axiosRole';

import { API_URLS, REGX, STATUS_CODE } from "../../utils/Constant";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment/moment";
import Breadcrumb from "../../common/BreadCrumb";
const CreateUser = () => {
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState(null);
  const [options, setOptions] = useState(null);
  const [formData, setFormData] = useState({
    userName: null,
    domainId: null,
    emailAddress: null,
    roleId: selectedValue,
    contactNo: null,
    description: null,
    createdBy: "Admin",
    createdAt: moment.utc(new Date()).format("YYYY-MM-DD HH:mm:ss.SSS"),
  });
  const [error, setError] = useState(null);

  const handleSelectChange = (event) => {
    setFormData({ ...formData, roleId: +event.target.value });
    setSelectedValue(event.target.value);
  };

  
  const validateData = () => {
    
    const newErrors = {};
    let isvalid = true;
    if (!formData.userName || !REGX.USER_NAME.test(formData.userName)) {
      isvalid = false;
      newErrors.userName = "Please Enter valid User Name ";
    }
    if (!formData.domainId || !REGX.NUMBERS_ONLY.test(formData.domainId)) {
      newErrors.domainId = "Please enter valid Domain ID";
      isvalid = false;
    }
    if (
      !formData.emailAddress ||
      !REGX.EMAIL_REGX.test(formData.emailAddress)
    ) {
      newErrors.emailAddress = "Please enter valid Email ID ";
      isvalid = false;
    }
    if (!formData.roleId) {
      newErrors.roleId = "Role is required";
      isvalid = false;
    }
    if (!formData.contactNo || !REGX.CONTACTNUMBER.test(formData.contactNo)) {
      newErrors.contactNo = "Please enter valid Contact No";
      isvalid = false;
    }
    if (!formData.description) {
      newErrors.description = "Description is required";
      isvalid = false;
    }else if (!REGX.ROLE_DESC.test(formData?.description)) {
      newErrors.description= "User Description cannot contain special characters";
      isvalid = false;
  }
    setError(newErrors);
    return isvalid;
  };

  const submitUser = () => {
  
    if (validateData()) {
      
      axiosRole.post(API_URLS.USER.CREATE_USER, formData)
        .then((res) => {
          if (res.status === 201) {
            toast.info("User Created Successfully", {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 500,
            });
            navigate(ROUTE_PATH.ROLE_USER, { state: { TAB: "USER" } });
          }
        })
        .catch((err) => {
      
          toast.error(err?.response?.data?.statusMessage, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 500,
          });
        });
    }
  };
  const getRole = () => {
    axiosRole.get(API_URLS.ROLE.ROLE_LIST)
      .then((res) => {
        let result = res?.data?.data;
        result = result.map((item) => {
          let newItem = {
            key: item.role_id,
            value: item.role_name,
          };
          return newItem;
        });
        setOptions([...result]);
      })
      .catch((err) => {
        console.error("Error  ", err);
      });
  };
  const handleChange = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  useEffect(() => {
    getRole();
  }, []);

  const handleKeyPress = (event) => {
    if (event.keyCode === 13 || event.which === 13) {
     submitUser();
    }
  }
  return (
    <>
      <Breadcrumb
        previous="Role/User Management"
        current="Create User"
        onclick={() => {
          navigate(ROUTE_PATH.ROLE_USER, { state: { TAB: "USER" } });
        }}
      />
      <div className="border-5 shadow rounded-4 create-user-container ">
        <div className="container ms-2 pt-1">
          <div className="row  position-relative">
            <div className="col-md-5 mt-4">
              <p>
                User Name<span className="textLabelRed">*</span>
              </p>
              <input
               onKeyDown={handleKeyPress}
                className="form-control"
                onChange={(e) => {
                  handleChange(e);
                }}
                name="userName"
              />
              {error?.userName && (
                <p className="text-danger pt-1 position-absolute">{error?.userName}</p>
              )}
            </div>
            <div className="col-md-5 mt-4">
              <p>
                Domain ID<span className="textLabelRed">*</span>
              </p>
              <input
               onKeyDown={handleKeyPress}
                className="form-control"
                onChange={(e) => {
                  handleChange(e);
                }}
                name="domainId"
              />
              {error?.domainId && (
                <p className="text-danger pt-1  position-absolute">{error?.domainId}</p>
              )}
            </div>
          </div>
          <div className="row  position-relative">
            <div className="col-md-5 mt-4">
              <p>
                Email ID<span className="textLabelRed">*</span>
              </p>
              <input
                className="form-control"
                onKeyDown={handleKeyPress}
                onChange={(e) => {
                  handleChange(e);
                }}
                name="emailAddress"
              />
              {error?.emailAddress && (
                <p className="text-danger pt-1 position-absolute">{error?.emailAddress}</p>
              )}
            </div>
            <div className="col-md-5 mt-4">
              <p>
                Contact No<span className="textLabelRed">*</span>
              </p>
              <input
               onKeyDown={handleKeyPress}
                className="form-control"
                onChange={(e) => {
                  handleChange(e);
                }}
                name="contactNo"
              />
              {error?.contactNo && (
                <p className="text-danger pt-1 position-absolute">{error?.contactNo}</p>
              )}
            </div>
          </div>
          <div className="row  position-relative">
            <div className="col-md-5 mt-4">
              <p>
                Role<span className="textLabelRed">*</span>
              </p>
              <SingleDropDown
                options={options}
                onSelectChange={handleSelectChange}
                selectedValue={selectedValue}
                placeholder="Select Role"
              />
              {error?.roleId && (
                <p className="text-danger pt-1 position-absolute">{error?.roleId}</p>
              )}
            </div>
          </div>
          <div className="row mt-4 position-relative">
            <div className="col-md-10">
              <p>
                User Description<span className="textLabelRed">*</span>
              </p>
              <textarea
               onKeyDown={handleKeyPress}
                class="form-control"
                rows="2"
                name="description"
                onChange={(e) => {
                  handleChange(e);
                }}
              ></textarea>
              {error?.description && (
                <p className="text-danger pt-1  position-absolute">{error?.description}</p>
              )}
            </div>
          </div>
          <div className="row mt-5 ">
            <div className="col-md-2">
              <Button
                className="create-button  mb-3"
                onClick={() => submitUser()}
              >
                Create User
              </Button>
            </div>
            <div className="col-md-3">
              <Button
                className="cancel-button  mb-3"
                onClick={() => {
                  navigate(ROUTE_PATH.ROLE_USER, { state: { TAB: "USER" } });
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CreateUser;
