import React from "react";
import Header from "../Header/Header";
import styles from "../../styles/layout/layout.module.scss"; // Import the SCSS module

const Layout = ({ children }) => {
  return (
    <div className={styles.layoutContainer}>
      <div className={styles.header}>
        <Header />
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default Layout;
