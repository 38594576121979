import React, { useState, useEffect, useRef } from 'react';
import styles from '../styles/Dropdown/dropdown.module.scss';
import downArrow from '../images/downArrow.png';
import upArrow from '../images/upArrow.svg';

const Dropdown = ({ options, onSelect, selected }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const optionArray = options?.map((option) => option?.value) || [];
  const handleOptionClick = (option) => {
    setSelectedOption(option.key);
    onSelect(option.value);
    closeDropdown();
    // handleLoanAccountchange(option);
  };

  // useEffect(() => {
  //   // Set the initially selected value to the first item in optionArray if it's null
  //   if (optionArray.length > 0) {
  //     const initialSelectedOption = optionArray[0];
  //     setSelectedOption(initialSelectedOption);
  //     onSelect(initialSelectedOption); // Send the initially selected value to the parent component
  //   } else {
  //     setSelectedOption(null);
  //     onSelect(null);
  //   }
  // }, [selectedOption]);

  useEffect(() => {
    setSelectedOption(options[0]?.key);
  }, [options]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    if (isDropdownOpen) {
      document.addEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isDropdownOpen]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };
  console.log(options,'options',selectedOption)
  return (
    <div className={styles.dropdownContainer} ref={dropdownRef}>
      <div className={styles.selectedOption} onClick={toggleDropdown}>
        {selectedOption ? selectedOption : 'Select Loan Account Number'}
        <img
          className={styles.arrow}
          src={isDropdownOpen ? upArrow : downArrow}
          alt={isDropdownOpen ? 'uparrow' : 'downarrow'}
        />
      </div>
      {isDropdownOpen && (
        <ul className={styles.optionsList}>
          {Array.isArray(options) &&
            options?.map((option) => {
              return (
                <li
                  key={option}
                  onClick={() => handleOptionClick(option)}
                  className={selectedOption == option.key ? styles.activeOption : null}
                >
                  {option.key}
                </li>
              );
            })}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
