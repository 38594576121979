import React from 'react';
import DataTable from 'react-data-table-component';
import { Loader } from 'rsuite';
import '../styles/Table/table.scss';
const CommonTable = (props) => {
  
  const customStyles = {
    rows: {
      style: {
        minHeight: '30px', // override the row height
      },
    },
    table: {
      font: 'Open Sans',
    },
    headRow: {
      style: {
        minHeight: '40px',
        backgroundColor: '#125BB2',
        color: '#ffffff',
        borderTopStyle: 'solid',
        borderTopWidth: '0px',
        borderTopColor: 'white',
        font: '13px Open Sans bold ',
      },
    },
    headCells: {
      style: {
        '&:not(:last-of-type)': {
          borderRightStyle: 'solid',
          borderRightWidth: '2px',
          borderRightColor: '#DFDFDF',
          font: '14px Open Sans bold',
        },
      },
    },
    cells: {
      style: {
        '&:not(:last-of-type)': {
          borderRightStyle: 'solid',
          borderRightWidth: '2px',
          borderRightColor: '#DFDFDF',
          font: '14px Open Sans bold',
          color: '#193256',
        },
      },
    },
  };

  const CustomLoader = () => {
    return  <Loader size="md" content="Medium" />;
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.id % 2 === 0,
      style: {
        backgroundColor: 'aqua',
        color: 'white',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
  ];

  return (
    <div className="p-2 table-responsive rounded-top mt-4">
      <DataTable
        columns={props.columns}
        data={props.data}
        pagination
        paginationServer
        paginationTotalRows={props.totalRows}
        //onChangeRowsPerPage={props.handlePerRowsChange}
        onChangePage={props.handlePageChange}
        paginationPerPage={props.perPage}
        paginationRowsPerPageOptions={[]}
        progressPending={props.loadings}
        progressComponent={<CustomLoader />}
        fixedHeader={false} // make the header fixed
        highlightOnHover={false}
        pointerOnHover
        customStyles={customStyles}
        onRowClicked={props.onRowClicked}
        responsive={true}
        selectableRowsVisibleOnly
        selectableRowsHighlight
        // selectableRowsComponent={FormCheck}
        data-tag="allowRowEvents"
      />
      
    </div>
  );
};
export default CommonTable;
