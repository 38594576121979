import React from "react";
import { ROUTE_PATH } from "../../../constants/Routes";
import {  Navigate, Outlet } from "react-router-dom";
import { persistor } from "../../../utils/Redux/store/Store";

const PrivateRoute = ({
  isAllowed,
  redirectPath =ROUTE_PATH.LOGIN,
  children,
}) => { 
  if (isAllowed ) {
    return children ? children : <Outlet />;
  }else{
  persistor.purge();
  persistor.flush();
  return <Navigate to={redirectPath} replace />;
  }
};
  export default PrivateRoute;