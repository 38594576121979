
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Store } from "./Redux/store/Store";

const defaultOptions = {

  baseURL: "https://communicationport.tatacapital.com:8084",
  method: "get",
  headers: {
    "Content-Type": "application/json",
    "content-security-policy": "default-src 'self'; script-src 'self'; object-src 'none';",
    "x-frame-options": "SAMEORIGIN",
    "permissions-policy": "geolocation=(self)",
    "x-access-token":"eyJhbGciOiJIUzI1NiJ9.eyJpZCI6NywianRpIjoiQkFqYXkuYXBwbGllZGNsb3VkY29tcHV0aW5nQHRhdGFjYXBpdGFsLmNvbSIsImlhdCI6MTcwOTE4NDUwNSwiZXhwIjoxNzA5MjcwOTA1fQ.6fsyqbZzoPkOjopZ1L1dZ8Y7P82yEVL4q7b7ur_LyzM",
    "Authorization": `Basic ${process.env.REACT_APP_BASIC_AUTH}`
  },
};

let instance = axios.create(defaultOptions);

instance.interceptors.request.use(
  function (config) {
    document.body.classList.add('loading-indicator');
    const selectLoginData = Store.getState("loginData")
     const token =selectLoginData.login.loginData.token ;
     config.headers["ngrok-skip-browser-warning"] = true;
    if (token) {
      config.headers["x-access-token"] = token;
    } else {
      delete config.headers["x-access-token"];
    }
    return config;
  },
  (error) => {
    console.error("Error", error);
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (response) => {
    document.body.classList.remove('loading-indicator');
    return Promise.resolve(response);
  },
  (error) => {
    // if (error.message === "Network Error" && !error.response) {
    // }
    document.body.classList.remove('loading-indicator');
    if (401 === error?.response?.status) {
  
        //LogOutTrigger()
      }

    if (error) {
      return Promise.reject(error);
    }
  }
);
export default instance;