import { useEffect, useState } from "react";
import { Card, Tab, Tabs, Button } from "react-bootstrap";
import "../../styles/Statistics/staticstics.scss";
import Dropdown from "../../common/Dropdown";
import SingleDropDown from "../../common/SingleDropdown";
import CustomDatePickerRange from "../../common/CustomDateRangePicker";
import { ReactComponent as Uptrend } from "../../images/uptrend.svg";
import { ReactComponent as Downtrend } from "../../images/downtrend.svg";
import LineChart from "../../common/LineChart";
import BarChart from "../../common/BarChart";
import DoughnutChart from "../../common/DoughnutChart";
import Menu from "../../images/Menu.svg";
import Piechart from "../../images/piechart.svg";
import { API_URLS, STATUS_CODE } from "../../utils/Constant";
import axios from '../../utils/axios';
import axioStatistics from '../../utils/axiosStatistic';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { format } from 'date-fns'

const Statistcs = () => {
  const [key, setKey] = useState("Source wise");
  const [activeTab, setActiveTab] = useState(true)
  const [tilesData, setTilesData] = useState([])
  const [graphData, setGraphData] = useState([])
  const [statusWiseData, setStatusWiseData] = useState([])
  const [communicationTypeDropdown,setCommunicationDropdown] = useState([]);
  const [communicationTypeSelectedOptions, setCommunicationTypeSelectedOptions] = useState([]);
  const [SourceDropdown,setSourceDropdown]=useState([]);
  const [sourceSelectedOptions, setSourceSelectedOptions] = useState([]);
  const [functionDropdown,setfunctionDropdown]=useState([]);
  const [functionSelectedOptions, setFunctionSelectedOptions] = useState([]);
  const [project,setProjectDropdown]=useState([]);
  const [downloadFlag,setDownloadFlag]=useState(false);
  const [startDateDP,setStartDateDP]=useState('');
  const [endDateDP,setEndDateDP]=useState('');
  const [lineFilter,setlineFilter]=useState({
    mode: 'SMS',
    month: 'Month'
  });
  const [barFilter,setBarFilter]=useState({
    mode: 'SMS',
    month: 'Month'
  });
  const commTypeDD = {
    SMS: "sms_details",
    EMAIL: "email_details"
  };

  const formattedTime=format(new Date(),'HH:mm:ss'); // Current time in 24-hour format
  const formattedDate=format(new Date(),'yyyy-MM-dd'); // Current Date
  const handleDownload = async() => {
    try{
      setDownloadFlag(true)
      /* Here an authorization header is set with authentication token value to authorize download of excel file. */
      const response = await fetch(
        `https://communicationport.tatacapital.com:8082/statistic-service/v1/statistics/download`,
        { 
          headers: {
            Authorization: `Basic ${process.env.REACT_APP_BASIC_AUTH}`,
          },
        }
      )
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Statistics_${formattedDate} ${formattedTime}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error downloading file: ', error);
    } finally {
      setDownloadFlag(false)
    } 
  }
  const getCommunicationType = () => {
    axios.get(API_URLS.COMMUNICATION_TIMELINE.COMMUNICATION_DROPDOWN)
    .then((res) => {
        let result = res?.data?.data;
        if(res.data.statusCode === STATUS_CODE.OK){
          result = result.map((item) => {
            let newItem = {
              label: item.typeOfCommunication,
              value: item.typeOfCommunication
            };
            return newItem;
          });
          if (result) {
            setCommunicationDropdown([...result]);
          }
        }
      })
      .catch((error) => {
        console.error(error)
      });
  }

  const getSourceSystem = () => {
    axios.get(API_URLS.COMMUNICATION_TIMELINE.SOURCE_DROPDOWN)
      .then((res) => {
        let result = res?.data?.data;
        if(res.data.statusCode === STATUS_CODE.OK){
          result = result.map((item) => {
            let newItem = {
              label: item.sourceSystem,
              value: item.sourceId
            };
            return newItem;
          });
          if (result) {
            setSourceDropdown([...result]);
          }
        }
      })
      .catch((error) => {
        console.error(error)
      });
  }

  const getStatistics = () => {
    const queryParams=new URLSearchParams();
    if(sourceSelectedOptions && sourceSelectedOptions.length > 0) {
      queryParams.set(
        "sourceSystem",
        `${sourceSelectedOptions.map((data) => {
          return data.label;
        })}`
      ); // An Event to be triggered on Source dropdown click
    }

    if(functionSelectedOptions && functionSelectedOptions.length > 0) {
      queryParams.set(
        "functionWise",
        `${functionSelectedOptions.map((data) => {
          return data.label;
        })}`
      ); // An Event to be triggered on Function dropdown click
    }

    if(communicationTypeSelectedOptions && communicationTypeSelectedOptions.length > 0) {
      queryParams.set(
        "communicationType",
        `${communicationTypeSelectedOptions.map((data) => {
          return data.label;
        })}`
      ); // An Event to be triggered on Communication Type dropdown click
    }
    
    // Start Date from Date Picker
    if(startDateDP && startDateDP.length > 0) {
      queryParams.set(
        "startDate",
        `${startDateDP}`
      ); // An Event to be triggered on when a date range is selected from Date Picker
    }
    
    // End Date from Date Picker
    if(endDateDP && endDateDP.length > 0) {
      queryParams.set(
        "endDate",
        `${endDateDP}`
      ); // An Event to be triggered on when a date range is selected from Date Picker
    }

    axioStatistics.get(API_URLS.STATISTICS.GET_STATISTICS,{params:queryParams})
      .then((res) => {
        let result = res?.data?.data;
        if(res.data.statusCode === STATUS_CODE.OK){
          setTilesData(result)
        }
      })
      .catch((error) => {
        console.error(error)
      });
  };

  const getGraphData = () => {
    const queryParams=new URLSearchParams();
    queryParams.set("type",lineFilter.month)
    if(lineFilter.mode==='SMS') {
      queryParams.set("tablename",commTypeDD.SMS)
    } else if(lineFilter.mode==='EMAIL') {
      queryParams.set("tablename",commTypeDD.EMAIL)
    }

    if(sourceSelectedOptions && sourceSelectedOptions.length > 0) {
      queryParams.set(
        "sourceSystem",
        `${sourceSelectedOptions.map((data) => {
          return data.label;
        })}`
      ); // An Event to be triggered on Source dropdown click
    }

    if(functionSelectedOptions && functionSelectedOptions.length > 0) {
      queryParams.set(
        "functionWise",
        `${functionSelectedOptions.map((data) => {
          return data.label;
        })}`
      ); // An Event to be triggered on Function dropdown click
    }

    if(communicationTypeSelectedOptions && communicationTypeSelectedOptions.length > 0) {
      queryParams.set(
        "communicationType",
        `${communicationTypeSelectedOptions.map((data) => {
          return data.label;
        })}`
      ); // An Event to be triggered on Communication Type dropdown click
    }
    
    axioStatistics.get(API_URLS.STATISTICS.GET_GRAPH_DATA,{params:queryParams})
      .then((res) => {
        let result = res?.data?.data[0];
        if(res.data.statusCode === STATUS_CODE.OK){
          setGraphData(result)
        }
      })
      .catch((error) => {
        console.error(error)
      });
  };

  const getStatusWiseData = () => {
    const queryParams=new URLSearchParams();
    queryParams.set("type",barFilter.month)
    if(barFilter.mode==='SMS') {
      queryParams.set("tablename",commTypeDD.SMS)
    } else if(barFilter.mode==='EMAIL') {
      queryParams.set("tablename",commTypeDD.EMAIL)
    }

    if(sourceSelectedOptions && sourceSelectedOptions.length > 0) {
      queryParams.set(
        "sourceSystem",
        `${sourceSelectedOptions.map((data) => {
          return data.label;
        })}`
      ); // An Event to be triggered on dropdown click
    }

    if(functionSelectedOptions && functionSelectedOptions.length > 0) {
      queryParams.set(
        "functionWise",
        `${functionSelectedOptions.map((data) => {
          return data.label;
        })}`
      ); // An Event to be triggered on dropdown click
    }

    if(communicationTypeSelectedOptions && communicationTypeSelectedOptions.length > 0) {
      queryParams.set(
        "communicationType",
        `${communicationTypeSelectedOptions.map((data) => {
          return data.label;
        })}`
      ); // An Event to be triggered on dropdown click
    }
    
    axioStatistics.get(API_URLS.STATISTICS.GET_STATUS_WISE,{params:queryParams})
      .then((res) => {
        let result = res?.data?.data;
        if(res.data.statusCode === STATUS_CODE.OK){
          setStatusWiseData(result)
        }
      })
      .catch((error) => {
        console.error(error)
      });
  };


  const getFunctionWise = () => {
    axios.get(API_URLS.STATISTICS.GET_FUNCTION_WISE)
    .then((res) => {
      let result = res?.data?.data;
      let statusCode = res?.data?.statusCode;
      if(statusCode === STATUS_CODE.OK){
        result = result.map((item) => {
          let newItem = {
            label: item.functionName,
            value: item.functionId,
          };
          console.log("newItem: ",newItem);
          return newItem;
        });
        if (result) {
          setfunctionDropdown([...result]);
        }
      }
    })
    .catch((error) => {
          console.error(error)
        });
  }

  const changelineFilter = (val,input) => {
    if(input === 'mode'){
      setlineFilter({...lineFilter,mode: val})
    }
    if(input === 'month'){
      setlineFilter({...lineFilter,month: val})
    }
  }

  const changeBarFilter = (val,input) => {
    if(input === 'mode'){
      setBarFilter({...barFilter,mode: val})
    }
    if(input === 'month'){
      setBarFilter({...barFilter,month: val})
    }
  }

  // This function handles Source Drop Down Change
  const handleSourceDDChange = (selected) => {
    setSourceSelectedOptions(selected);
    console.log("selected: ", selected); // Log selected options to the console
  };

  // This function Clears the selected values in Source Drop Down useState
  const clearSourceDropDown = (data) => {
    setSourceSelectedOptions([]);
  };
  

  // This function handles Function Drop Down Change
  const handleFunctionDDChange = (selected) => {
    setFunctionSelectedOptions(selected);
    console.log("selected: ", selected); // Log selected options to the console
  };

  // This function Clears the selected values in Function Drop Down useState
  const clearFunctionDropDown = (data) => {
    setFunctionSelectedOptions([]);
  };
  

  // This function handles Communication Type Drop Down Change
  const handlecommunicationTypeDDChange = (selected) => {
    setCommunicationTypeSelectedOptions (selected);
    console.log("selected: ", selected); // Log selected options to the console
  };

  // This function Clears the selected values in Communication Type Drop Down useState
  const clearcommunicationTypeDropDown = (data) => {
    setCommunicationTypeSelectedOptions([]);
  };
  
  // This function sets the selected start & end date values in their respective useState variables
  const handleDateRangeChange = async (start, end) => {
    setStartDateDP(start)
    setEndDateDP(end)
  };

  useEffect(()=>{
    getStatistics();
    getGraphData();
    getStatusWiseData();
    getSourceSystem();
    getFunctionWise();
    getCommunicationType();
  }, [sourceSelectedOptions, functionSelectedOptions, communicationTypeSelectedOptions]);
  
  /* Calling the Get Statistics api function on the change of start and end date in the date picker drop down */
  useEffect(()=>{
  getStatistics();
  }, [startDateDP, endDateDP]);

  useEffect(()=>{
    getGraphData();
  },[lineFilter])
  
  useEffect(() => {
    getStatusWiseData();
  },[barFilter])
  
  useEffect(()=>{

    // if ladder to Set all the selectable values from Drop Down to the SelectedOptions useState variable for the 3 available Drop Downs in their respective tabs so as to pass all the selectable values of that Drop Down when switching to a specific tab
    if (key === "Source") {  // Set the SourceSelectedOptions useState to SourceDorpDown Array if selected Tab === "Source"
      setSourceSelectedOptions(SourceDropdown)
    }
    console.log('sourceSelectedOptions outside if: ',sourceSelectedOptions);
    if (key === "Function") {  // Set the functionSelectedOptions useState to functionDropdown Array if selected Tab === "Function"
      setFunctionSelectedOptions(functionDropdown)
    }
    if (key === "Communication Type") {  // Set the CommunicationTypeSelectedOptions useState to communicationTypeDropdown Array if selected Tab === "Communication Type"
      setCommunicationTypeSelectedOptions(communicationTypeDropdown)
    }

    // if ladder to reset the selected Drop Down values from the 3 available Drop Downs in their respective tabs
    if (key !== "Source") {  // Reset the SourceSelectedOptions useState to an empty Array if selected Tab !== "Source"
      clearSourceDropDown();
    }
    if (key !== "Function") {  // Reset the FunctionSelectedOptions useState to an empty Array if selected Tab !== "Function"
      clearFunctionDropDown();
    }
    if (key !== "Communication Type") {  // Reset the CommunicationTypeSelectedOptions useState to an empty Array if selected Tab !== "Communication Type"
      clearcommunicationTypeDropDown();
    }
  }, [key]);

  return (
    <>
      <div className="staticstics-container mb-3">
        <div className="sub-header d-flex mt-1 ">
          <Tabs
            defaultActiveKey="Source wise"
            id="fill-tab-example"
            className="mb-3 rounded-1 bg-white shadow"
            activeKey={key}
            onSelect={(key) => setKey(key)}
          >
            <Tab eventKey="Source" title="Source"
            >
              <div className="d-flex flex-row w-100">
                <ReactMultiSelectCheckboxes
                  className="shadow dropdown"
                  style={{width: "100%"}}
                  placeholderButtonLabel="Select Source"
                  isClearable
                  options={SourceDropdown}
                  value={sourceSelectedOptions}
                  onChange={handleSourceDDChange}
                />
                <div
                  className="clear_filter"
                  style={{width: "60px",margin: "0"}}
                  onClick={clearSourceDropDown}
                >
                  Clear
                </div>
              </div>
            </Tab>
            <Tab eventKey="Function" title="Function"
            >
              <div className="d-flex flex-row w-100">
                <ReactMultiSelectCheckboxes
                  className="w-100 shadow dropdown"
                  placeholderButtonLabel="Select Function"
                  isClearable
                  options={functionDropdown}
                  value={functionSelectedOptions}
                  onChange={handleFunctionDDChange}
                />
                <div
                  className="clear_filter"
                  style={{ width: "60px", margin: "0" }}
                  onClick={clearFunctionDropDown}
                >
                  Clear
                </div>
              </div>
            </Tab>
            <Tab eventKey="Communication Type" title="Communication Type"
            >
              <div className="d-flex flex-row w-100">
                <ReactMultiSelectCheckboxes
                  className="shadow dropdown"
                  style={{width: "100%"}}
                  placeholderButtonLabel="Select Communication Type"
                  isClearable
                  options={communicationTypeDropdown}
                  value={communicationTypeSelectedOptions}
                  onChange={handlecommunicationTypeDDChange}
                />
                <div
                  className="clear_filter"
                  style={{width: "60px",margin: "0"}}
                  onClick={clearcommunicationTypeDropDown}
                >
                  Clear
                </div>
              </div>

            </Tab>
            {/* This Project Tab is currently not required so it is commented, uncommented in future if required */}
            {/* <Tab eventKey="Project" title="Project"
              >
              <div className="d-flex gap-3">
              <ReactMultiSelectCheckboxes
                className="w-100 shadow dropdown"
                placeholderButtonLabel="Select Project"
               
                isClearable
                options={project}
              />
                 <ReactMultiSelectCheckboxes
                className="w-100 shadow dropdown"
                placeholderButtonLabel="Select Source"
                
                isClearable
                options={SourceDropdown}
                
              />
              </div>
            </Tab> */}
          </Tabs>
          <div className="ms-auto download-btn">
            <Button variant="warning" className="download-main" onClick={handleDownload} disabled={downloadFlag}>
              Download
            </Button>
          </div>
          <div className="mobileview_date">
            {/* Passed a function as a prop to the date picker component to handle and make use of the selected start & end dates */}
            <CustomDatePickerRange onDateRangeChange={handleDateRangeChange} />
          </div>
        </div>
        <div className="p-3 rounded-2 shadow bg-white">
          <div className="row  card-container">
            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 my-3">
              <Card className="card-1">
                <Card.Body>
                  <span className="percentage">
                    {tilesData[0]?.emailDTO?.successPercentage.toFixed(2)}%
                    <span className="ps-2">
                      <Uptrend></Uptrend>
                    </span>
                  </span>
                  <Card.Subtitle className="mb-1 card-subtitle ">
                    Total Email Opportunity
                  </Card.Subtitle>
                  <Card.Text className="count">{tilesData[0]?.emailDTO?.totalOpportunities}</Card.Text>
                  <hr className="seperator"/>
                  <div className="d-flex gap-5 mt-1">
                    <div className="d-grid align-items-center justify-content-center">
                      <span className='card-subtitle'>
                      Delivered
                      </span>
                      <span className="card-count">
                        {tilesData[0]?.emailDTO?.deliveredEmails}
                      </span>
                    </div>
                    <div className="d-grid align-items-center justify-content-center">
                      <span className='card-subtitle'>
                        Error
                      </span>
                      <span className="card-count">
                        {tilesData[0]?.emailDTO?.notDeliveredEmails}
                      </span>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 my-3">
              <Card className="card-2">
                <Card.Body>
                  <span className="percentage">
                    {tilesData[0]?.smsDTO?.successPercentage.toFixed(2)}%
                    <span className="ps-2">
                      <Uptrend></Uptrend>
                    </span>
                  </span>
                  <Card.Subtitle className="mb-1 card-subtitle">
                    Total SMS Opportunity
                  </Card.Subtitle>
                  <Card.Text className="count">{tilesData[0]?.smsDTO?.totalOpportunities}</Card.Text>
                  <hr className="seperator"/>
                  <div className="d-flex gap-5 mt-1">
                    <div className="d-grid align-items-center justify-content-center">
                      <span className='card-subtitle'>
                        Delivered
                      </span>
                      <span className="card-count">
                        {tilesData[0]?.smsDTO?.deliveredSms}
                      </span>
                    </div>
                    <div className="d-grid align-items-center justify-content-center">
                      <span className='card-subtitle'>
                        Error
                      </span>
                      <span className="card-count">
                        {tilesData[0]?.smsDTO?.notDeliveredSms}
                      </span>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
            {/* <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 my-3">
              <Card className="card-3">
                <Card.Body>
                  <span className="percentage">
                    100%
                    <span className="percentage ps-2">
                      <Uptrend></Uptrend>
                    </span>
                  </span>
                  <Card.Subtitle className="mb-1 card-subtitle ">
                    Total WhatsApp Opportunity
                  </Card.Subtitle>
                  <Card.Text className="count">2000</Card.Text>
                  <hr className="seperator"/>
                  <div className="d-flex gap-5 mt-1">
                    <div className="d-grid align-items-center justify-content-center">
                      <span className='card-subtitle'>
                        Delivered
                      </span>
                      <span className="card-count">
                        2000
                      </span>
                    </div>
                    <div className="d-grid align-items-center justify-content-center">
                      <span className='card-subtitle'>
                        Error
                      </span>
                      <span className="card-count">
                        2000
                      </span>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 my-3">
              <Card className="card-4">
                <Card.Body>
                  <span className="percentage">
                    100%
                    <span className="ps-2">
                      <Uptrend></Uptrend>
                    </span>
                  </span>
                  <Card.Subtitle className="mb-1 card-subtitle ">
                    Total Physical Opportunity
                  </Card.Subtitle>
                  <Card.Text className="count">2000</Card.Text>
                  <hr className="seperator"/>
                  <div className="d-flex gap-5 mt-1">
                    <div className="d-grid align-items-center justify-content-center">
                      <span className='card-subtitle'>
                        Delivered
                      </span>
                      <span className="card-count">
                        2000
                      </span>
                    </div>
                    <div className="d-grid align-items-center justify-content-center">
                      <span className='card-subtitle'>
                        Error
                      </span>
                      <span className="card-count">
                        2000
                      </span>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div> */}
          </div>
          <div className="row">
            <div className="graph col-lg-9 col-md-6 col-sm-6 col-xs-1">
              <div className="row">
                <div className="graph col-lg-6 col-md-6 col-sm-6 col-xs-1">
                  <div className="shadow rounded-2 p-2">
                    <div className="d-flex justify-content-between mt-2">
                      <p>Overview</p>
                      <div className="d-flex gap-2">
                        <SingleDropDown
                          className="w-100 graph-drop "
                          placeholder="Mode"
                          options={[
                            { key: "SMS", value: "SMS" },
                            { key: "EMAIL", value: "EMAIL" },
                          ]}
                          selectedValue={lineFilter.mode}
                          onSelectChange={(e)=>changelineFilter(e.target.value,'mode')}
                        ></SingleDropDown>
                        <SingleDropDown
                          className="w-100 graph-drop "
                          placeholder="Month"
                          options={[
                            { key: "Week", value: "Week" },
                            { key: "Month", value: "Month" },
                            { key: "Year", value: "Year" },
                          ]}
                          selectedValue={lineFilter.month}
                          onSelectChange={(e)=>changelineFilter(e.target.value,'month')}
                        ></SingleDropDown>
                      </div>
                    </div>
                    {/* Passing the the selected month key value of lineFilter useState of LineChart Component in lineFilterMonthValue prop */}
                    <LineChart
                      graphData={graphData  ?? []}  // if graphData is null or undefined then the graphData props will be set to an empty array to avoid breaking of code
                      lineFilterMonthValue={lineFilter.month}
                    />
                  </div>
                </div>
                <div className="graph col-lg-6 col-md-6 col-sm-6 col-xs-12 ">
                  <div className="shadow rounded-2 p-2">
                    <div className="d-flex justify-content-between mt-2">
                      <p>Overview</p>
                      <div className="d-flex gap-2">
                        <SingleDropDown
                          className="w-100 graph-drop "
                          placeholder="Mode"
                          options={[
                            { key: "SMS", value: "SMS" },
                            { key: "EMAIL", value: "EMAIL" },
                          ]}
                          selectedValue={barFilter.mode}
                          onSelectChange={(e)=>changeBarFilter(e.target.value,'mode')}
                        ></SingleDropDown>
                        <SingleDropDown
                          className="w-100 graph-drop "
                          placeholder="Month"
                          options={[
                            { key: "Week", value: "Week" },
                            { key: "Month", value: "Month" },
                            { key: "Year", value: "Year" },
                          ]}
                          selectedValue={barFilter.month}
                          onSelectChange={(e)=>changeBarFilter(e.target.value,'month')}
                        ></SingleDropDown>
                      </div>
                    </div>
                    {/* Passing the the selected month key value of barFilter useState of BarChart Component in barFilterMonthValue prop */}
                    <BarChart
                      statusWiseData={statusWiseData ?? []}  // if statusWiseData is null or undefined then the statusWiseData props will be set to an empty array to avoid breaking of code
                      barFilterMonthValue={barFilter.month}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="graph doughnut col-lg-3 col-md-6 col-sm-6 col-xs-12 ">
              <div className="shadow rounded-2 p-2 px-3">
                <div className="d-flex justify-content-between mt-2">
                  <p>Overview</p>
                  <div className="d-flex gap-2 shadow rounded-2 p-1">
                    <div className="px-2 py-1 rounded-2" style={{backgroundColor: activeTab ? "yellow" : "white"}}>
                        <img className="overviewIcon" onClick={()=>setActiveTab(true)} src={Menu} />
                    </div>
                    <div className="px-2 py-1 rounded-2" style={{backgroundColor: !activeTab ? "yellow" : "white"}}>
                        <img className="overviewIcon" onClick={()=>setActiveTab(false)} src={Piechart} />
                    </div>
                  </div>
                </div>
                {activeTab ? <>
                    <div className="row py-4">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <span>Opportunities</span>
                        <p>{tilesData[0]?.totalDTO?.totalOpportunities}</p>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <span>Delivered</span>
                        <p>{tilesData[0]?.totalDTO?.totalDeliveredCount}</p>
                      </div>
                    </div>
                    <div className="row py-4">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <span>Error</span>
                        <div className="d-flex">
                          {/* <p>{tilesData[0]?.totalDTO?.totalErrorCount}</p> */}
                          {tilesData[0]?.totalDTO?.successPercentage !==
                            undefined &&
                          !isNaN(tilesData[0]?.totalDTO?.successPercentage) ? (
                            <p>
                              {(
                                100 - tilesData[0]?.totalDTO?.successPercentage
                              ).toFixed(2)}
                              %
                            </p>
                          ) : "%"}
                          <span className="downward p-1 ms-2">
                            <Downtrend></Downtrend>
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <span>Success</span>
                        <div className="d-flex">
                          <p>{tilesData && tilesData[0]?.totalDTO && typeof tilesData[0]?.totalDTO.successPercentage === "number" ? `${tilesData[0]?.totalDTO?.successPercentage.toFixed(2)}%` : ""}</p>
                          <span className="upward p-1 ms-2">
                            <Uptrend></Uptrend>
                          </span>
                        </div>
                      </div>
                </div></> :
                <div className="mt-4"><DoughnutChart data={tilesData[0]?.totalDTO}/></div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Statistcs;
