import React from "react";
import { Form } from "react-bootstrap";
// import "../../component/commonstyle.scss";
const SingleDropDown = (props) => {
  return (
    <div className="dropdown">
      <Form.Select
        className={props.className}
        onChange={props.onSelectChange}
        value={props.selectedValue} // Preselected value
      >
        {props.placeholder && (
          <option key={props.placeholder} hidden value>
            {props.placeholder}
          </option>
        )}
        {props?.options?.map((i) => (
          <option value={i.key}>{i.value}</option> // Set the value attribute
        ))}
      </Form.Select>
    </div>
  );
};
export default SingleDropDown;
