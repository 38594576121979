import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useImperativeHandle,
} from "react";
import styles from "../styles/DropdownSideBar/DropdownSideBar.module.scss";
import downArrow from "../images/downArrow.png";
import upArrow from "../images/upArrow.svg";

const DropdownSideBar = React.forwardRef(
  (
    { options, onSelect, defaultLabel, value, category, onDropdownChange },
    ref
  ) => {
    const [selectedOption, setSelectedOption] = useState(value || null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [label, setLabel] = useState(
      value ? getOptionValue(value) : defaultLabel
    );
    const dropdownRef = useRef(null);

    useEffect(() => {
      if (category === "Type of Communication") {
        setLabel(
          selectedOption ? selectedOption.typeOfCommunication : defaultLabel
        );
      } else if (category === "Source System") {
        setLabel(selectedOption ? selectedOption.sourceSystem : defaultLabel);
      } else if (category === "Vendor Name") {
        setLabel(selectedOption ? selectedOption.vendorName : defaultLabel);
      }
    }, [selectedOption, defaultLabel, category]);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target)
        ) {
          closeDropdown();
        }
      };

      document.addEventListener("click", handleClickOutside);

      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }, []);

    const resetSelectedValue = () => {
      setSelectedOption(null);
      setLabel(defaultLabel);
    };

    const handleOptionClick = (option) => {
      setSelectedOption(option);

      if (onDropdownChange) {
        setSelectedOption((prevOption) => {
          onDropdownChange(category, prevOption, option);
          return option;
        });
      }

      setLabel(getOptionValue(option));
      closeDropdown();
    };

    const toggleDropdown = () => {
      setIsDropdownOpen(!isDropdownOpen);
    };

    const closeDropdown = () => {
      setIsDropdownOpen(false);
    };

    useImperativeHandle(ref, () => ({
      resetSelectedValue,
    }));

    const getOptionKey = (option) => {
      switch (category) {
        case "Type of Communication":
          return option.communicationId;
        case "Source System":
          return option.sourceId;
        case "Vendor Name":
          return option.vendorId;
        default:
          return null;
      }
    };

    const getOptionValue = (option) => {
      switch (category) {
        case "Type of Communication":
          return option.typeOfCommunication;
        case "Source System":
          return option.sourceSystem;
        case "Vendor Name":
          return option.vendorName;
        default:
          return null;
      }
    };

    const renderDropDown = useCallback(
      (option) => {
        const optionValue = getOptionValue(option);

        return (
          <li
            key={getOptionKey(option)}
            onClick={() => handleOptionClick(option)}
          >
            {optionValue}
          </li>
        );
      },
      [handleOptionClick]
    );

    return (
      <div ref={dropdownRef} className={styles.dropdownContainer}>
        <div className={styles.selectedOption} onClick={toggleDropdown}>
          {label}
          <img
            className={styles.arrow}
            src={isDropdownOpen ? upArrow : downArrow}
            alt={isDropdownOpen ? "uparrow" : "downarrow"}
          />
        </div>
        {isDropdownOpen && (
          <ul className={styles.optionsList}>
            {options.map((option) => renderDropDown(option))}
          </ul>
        )}
      </div>
    );
  }
);

export default DropdownSideBar;
