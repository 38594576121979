import React from "react";
import '../styles/Bredcrumb/breadcrumb.scss';

const Breadcrumb=(props)=>{

    return(
        <div className="pb-2"> 
             <label className="previous text-nowrap m-0" onClick={props.onclick}>{props.previous}&nbsp;<span className="arrow">&gt;&gt;</span></label><span className="current text-nowrap">{props.current}</span>
        </div>
    )
}

export default Breadcrumb;