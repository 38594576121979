import Axios from "../../utils/axios";
import { API_URLS } from "../../utils/Constant";

export const getEmailDetails = async (queryParams) => {
  try {
    const response = await Axios.get(
      API_URLS.COMMUNICATION_TIMELINE.EMAIL_DETAILS,
      {
        params: queryParams,
      }
    );

    if (response.status===200) {
      const data = response?.data?.data;
      const totalCount = response?.data?.totalCount
      return {data,totalCount};
    } 
    else {
      return null;
      throw new Error(response.statusMessage);
    }
  } catch (error) {
    console.error("IN ERROR ");
    return null;
    throw error;
  }
};
