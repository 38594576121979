import Axios from "../../utils/axios";
import { API_URLS } from "../../utils/Constant";

export const getSourceDropdown = async () => {
  try {
    const response = await Axios.get(
      API_URLS.COMMUNICATION_TIMELINE.SOURCE_DROPDOWN
    );

    if (response.status===200) {
      const data = response?.data?.data;
      return data;
    } else {
      throw new Error(response.statusMessage);
    }
  } catch (error) {
    throw error;
  }
};
