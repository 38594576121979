import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const colors = [
  "#2AB4E8",
  "#BC7CD9",
  "#0955B4",
  "#55BF8E",
  "#E7895B",
  "#95E0FC",
  "#E0AFF6",
  "#78B3FE",
  "#9DF5CC",
  "#FFBC9B",
  "#2AB4E8",
  "#BC7CD9",
  "#0955B4",
  "#55BF8E",
  "#E7895B",
  "#95E0FC",
  "#E0AFF6",
  "#78B3FE",
  "#9DF5CC",
  "#FFBC9B",
];

const BarChart = ({ statusWiseData, barFilterMonthValue }) => {
  
  /* 
  A function to extract the period values in accending order without duplicate values
  This function returns 2 values `Week_distinctPeriods` & `Week_Month_Year_distinctPeriods`
  where Week_distinctPeriods is a custom return value for the Week Dorp Down in format `["Week 14", "Week 15", "Week 16"]`
  where Week_Month_Year_distinctPeriods is  a return value for all Drop Down Options which are:
  1. Week in format `["14", "15", "16"]`
  2. Month in format `["2", "3", "4"]`
  3. Year in format `["2022", "2023", "2024"]`
  `Week_distinctPeriods`  is used for the x-axis Labels for the Week Drop Down Filter in Bar Graph
  `Week_Month_Year_distinctPeriods` is used for the setting the count key values in the Dataset Array for all the 3 (Week, Month & Year) Drop Down Filter in the Bar Graph
  */
  function extractPeriodValues(statusWiseData) {
    let Week_distinctPeriods = [];
    if (barFilterMonthValue === "Week") {
      Week_distinctPeriods = [
        ...new Set(statusWiseData.map((entry) => `Week ${entry.period}`)),
      ].sort();
    }
      const Week_Month_Year_distinctPeriods = [
        ...new Set(statusWiseData.map((entry) => entry.period)),
      ].sort();
    return { Week_distinctPeriods, Week_Month_Year_distinctPeriods };
  }
  let labels = [];
  if (barFilterMonthValue === "Week") {
    statusWiseData?.map((item) => {
      /* This `extractPeriodValues(statusWiseData).Week_distinctPeriods` is used to set the x-axis labels for Week */
      labels = extractPeriodValues(statusWiseData).Week_distinctPeriods;
    });
  } else if (barFilterMonthValue === "Month") {
    statusWiseData?.map((item) => {
    /* This `extractPeriodValues(statusWiseData).Week_distinctPeriods` is used to set the x-axis labels for Month */
      if ( item?.label ? !labels.includes(moment(item?.period).format("MMM")) : "" ) {
        labels.push(moment(item?.period).format("MMM"));
      }
    });
  } else if (barFilterMonthValue === "Year") {
    statusWiseData?.map((item) => {
      /* This `extractPeriodValues(statusWiseData).Week_distinctPeriods` is used to set the x-axis labels for Year */
      if (!labels.includes(item?.period)) {
        labels.push(item?.period ?? "");
      }
    });
  }

  const DataFormater = (number) => {
    if (number > 1000000000) {
      return (number / 1000000000).toString() + "B";
    } else if (number > 1000000) {
      return (number / 1000000).toString() + "M";
    } else if (number > 1000) {
      return (number / 1000).toString() + "K";
    } else {
      return number.toString();
    }
  };

  // This is a function which transforms the API Respnse data into the final Dataset which will then be mapped and plotted on the Bar Chart
  function transformData(statusWiseData) {
    let transformedData = {};

    statusWiseData.forEach(({ label, count, period }) => {
      if (!transformedData[label]) {
        transformedData[label] = extractPeriodValues(statusWiseData).Week_Month_Year_distinctPeriods
          .map((item, index) => {
            item = 0;  /* Used to set each element in the labels value to 0 so as to dynamically create a array of 0's which can be lateron used to set the data in the correct index */
            return item;
          });
      }

      extractPeriodValues(statusWiseData).Week_Month_Year_distinctPeriods.map(
        (element, index) => {
          if (period === element) {
            transformedData[label][index] = count; /* Used to set each element in the labels which was previously set to 0 to the correct count key value */
          }
        }
      );
    });

    return Object.entries(transformedData).map(([label, data]) => ({
      label,
      data,
    }));
  }

  // The Final Data set which is to be mapped and displayed on the bar graph is stored in the `FinalDataSet` variable
  let FinalDataSet = transformData(statusWiseData);

  const tempData = FinalDataSet?.map((item, index) => {
    return {
      label: item?.label,
      data: item?.data,
      borderColor: "#FFF",
      backgroundColor: [colors[index]],
      borderWidth: 1,
      pointStyle: "circle",
      pointRadius: 5,
      pointHoverRadius: 10,
      barThickness: 5,
      formatter: function(context) {
        return context / 1000 + "k";
      }
    }
  });

  const data = {
    labels,
    datasets: tempData,
  };
  
  const options = {
    plugins: {
      legend: {
        display: true
      },
    },
    responsive: true,
    scales: {
      y: {
        ticks: {
          callback: function (value, index, values) {
            return DataFormater(value);
          },
        },
      },
    },
  };
  
  return <Bar options={options} data={data} height={200} width={300} />;
};
export default BarChart;
