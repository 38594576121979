import { ROUTE_PATH } from "../../constants/Routes";
import { useDispatch } from 'react-redux';
import { storeLoginData } from "../../utils/Redux/Reducer/LoginReducer";
const { useEffect } = require("react");
const { useNavigate, useLocation } = require("react-router-dom");

const SuccessHandler =()=>{

    const navigate =useNavigate();
    const location=useLocation();
    const dispatch = useDispatch();
    useEffect(()=>{
        const urlParams = new URLSearchParams(location.search);
        const decodedJson = decodeURIComponent(urlParams?.get('data'));
        const jsonObject = JSON.parse(decodedJson);
        dispatch(storeLoginData(jsonObject));
        navigate(ROUTE_PATH.COMMUNICATION_TIMELINE);
    },[])
    return(
        <>
        </>
    );

}
export default SuccessHandler;