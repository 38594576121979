import React, {
  useState,
  useEffect,
  useRef,
  useReducer,
  useCallback,
} from "react";
import SearchBox from "../../common/SearchBox";
import style from "../../styles/CommunicationTimeline/communicationTimline.module.scss";
import CustomButton from "../../common/CustomButton";
import CustomDatePickerRange from "../../common/CustomDateRangePicker";
import Download from "../../images/Download.svg";
import ProfileImage from "../../images/Profile.svg";
import UpwardImage from "../../images/Upward.svg";
import Dropdown from "../../common/Dropdown";
import DropdownSideBar from "../../common/DropdownSideBar";
import DashboardNavigation from "../../common/DashboardNavigation";
import Timeline from "../../common/TimeLine/TimeLine.jsx";
import { getCommunicationDropdown } from "../../services/timelineCommunication/CommunicationDropdown.jsx";
import { getSourceDropdown } from "../../services/timelineCommunication/SourceDropdown.jsx";
import { getVenderDropdown } from "../../services/timelineCommunication/VenderDropdown.jsx";
import { getUserDetails } from "../../services/timelineCommunication/UserDetails.jsx";
import { getEmailDetails } from "../../services/timelineCommunication/EmailDetails.jsx";
import { getSmsDetails } from "../../services/timelineCommunication/SmsDetails.jsx";
import { getAllDetails } from "../../services/timelineCommunication/AllDeatils.jsx";
import { getDeliveryStatuses } from "../../common/DeliveryStatusAPI/GetDeliveryStatuses.jsx";
import DataNoFound from "../../common/DataNoFound.jsx";
import Loader from "../../common/Loader.jsx";
import { API_URLS, REGX } from "../../utils/Constant.jsx";
import { toast } from "react-toastify";

const initialState = {
  searchLoanNumber: "",
  searchMobileNumber: "",
  searchEmail: "",
  searchPANNumber: "",
  mobileError: "",
  panError: "",
  emailError: "",
  loanNumberError: "",
  loanData: [],
  emailData: [],
  smsData: [],
  allData: [],
  communicationOptions: [],
  sourceOptions: [],
  venderOptions: [],
  startDate: null,
  endDate: null,
  communicationType: "",
  sourceSystem: "",
  vendorName: "",
  isLoading: false,
  searchButtonLoading: false,
  activeTab: "All",
  LoanNumber: "",
  selectedValue: "",
  apiLimit: 10,
  apiOffset: 0,
  currentPage: 1,
  totalCount: 0,
  clearAll:false
};
const reducer = (state, action) => {
  switch (action.type) {
    case "SET_SEARCH_LOAN_NUMBER":
      return {
        ...state,
        searchLoanNumber: action.payload,
        loanNumberError: "",
      };
    case "SET_SEARCH_MOBILE_NUMBER":
      return { ...state, searchMobileNumber: action.payload, mobileError: "" };
    case "SET_SEARCH_EMAIL":
      return { ...state, searchEmail: action.payload, emailError: "" };
    case "SET_SEARCH_PAN_NUMBER":
      return { ...state, searchPANNumber: action.payload, panError: "" };
    case "SET_MOBILE_ERROR":
      return { ...state, mobileError: action.payload };
    case "SET_PAN_ERROR":
      return { ...state, panError: action.payload };
    case "SET_EMAIL_ERROR":
      return { ...state, emailError: action.payload };
    case "SET_LOAN_NUMBER_ERROR":
      return { ...state, loanNumberError: action.payload };
    case "SET_LOAN_DATA":
      return { ...state, loanData: action.payload };
    case "SET_EMAIL_DATA":
      return { ...state, emailData: action.payload };
    case "SET_SMS_DATA":
      return { ...state, smsData: action.payload };
    case "SET_ALL_DATA":
      return { ...state, allData: action.payload };
    case "SET_START_DATE":
      return { ...state, startDate: action.payload };
    case "SET_END_DATE":
      return { ...state, endDate: action.payload };
    case "SET_COMMUNICATION_OPTIONS":
      return { ...state, communicationOptions: action.payload };
    case "SET_SOURCE_OPTIONS":
      return { ...state, sourceOptions: action.payload };
    case "SET_VENDER_OPTIONS":
      return { ...state, venderOptions: action.payload };
    case "SET_IS_LOADING":
      return { ...state, isLoading: action.payload };
    case "SET_IS_SEARCHLOADING":
      return { ...state, searchButtonLoading: action.payload };
    case "SET_ACTIVE_TAB":
      return { ...state, activeTab: action.payload };
    case "SET_LOAN_NUMBER":
      return { ...state, LoanNumber: action.payload };
    case "SET_SELECTED_VALUE":
      return { ...state, selectedValue: action.payload };
    case "SET_COMMUNICATION_TYPE":
      return { ...state, communicationType: action.payload };

    case "SET_SOURCE_SYSTEM":
      return { ...state, sourceSystem: action.payload };
    case "CLEAR_ALL":
      return { ...state, clearAll: action.payload };

    case "SET_VENDOR_NAME":
      return { ...state, vendorName: action.payload };
    case "RESET_FILTERS":
      return {
        ...state,
        communicationType: null,
        sourceSystem: null,
        vendorName: null,
      };

    case "SET_API_OFFSET":
      return {
        ...state,
        apiOffset: action.payload,
      };
    case "SET_API_LIMIT":
      return { ...state, apiLimit: action.payload };
    case "SET_API_CURRENT_PAGE":
      return { ...state, currentPage: action.payload}
    case "SET_API_TOTAL_PAGE":
      return { ...state, totalCount: action.payload}
    default:
      return state;
  }
};

const baseURL = process.env.REACT_APP_BASE_URL;
const CommunicationTimeline = () => {
  const [dropdownData,setDropdownData] = useState([])
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    searchLoanNumber,
    searchMobileNumber,
    searchEmail,
    searchPANNumber,
    mobileError,
    panError,
    emailError,
    loanNumberError,
    loanData,
    emailData,
    smsData,
    allData,
    communicationOptions,
    sourceOptions,
    venderOptions,
    startDate,
    endDate,
    isLoading,
    searchButtonLoading,
    activeTab,
    LoanNumber,
    selectedValue,
    communicationType,
    sourceSystem,
    vendorName,
    apiOffset,
    apiLimit,
    currentPage,
    totalCount,
    clearAll
  } = state;

  const [DSCombArr,setDSCombArr] = useState([]);

  const appendAPIAtrbt = (searchCriteria)=>{
    if (!loanData?.email_id || !loanData?.mobile_number) {
      delete searchCriteria.all;
      if (searchEmail) searchCriteria.emailId = searchEmail;
      if (searchMobileNumber) searchCriteria.mobileNumber = searchMobileNumber;
    }
  }
  
  const fetchData = async () => {
    try {
      dispatch({ type: "SET_IS_LOADING", payload: true });
      let searchCriteria = {};
      if(startDate && endDate){
        searchCriteria.startDate=startDate;
        searchCriteria.endDate=endDate;
      }
      if (activeTab === "Email") {
        if (searchLoanNumber) {
          searchCriteria.loanAccountNumber = searchLoanNumber;
          searchCriteria.offset = apiOffset;
          searchCriteria.limit = apiLimit;
          delete searchCriteria.mobileNumber;
          if(searchLoanNumber==='True'){
            delete searchCriteria.loanAccountNumber
            if (LoanNumber) searchCriteria.loanAccountNumber = LoanNumber;
            if (searchEmail) searchCriteria.emailId = searchEmail;
            if (!searchEmail) searchCriteria.emailId = loanData.email_id;
            if (searchPANNumber) searchCriteria.panNumber = searchPANNumber;
            searchCriteria.all = 'True'
          }
        }
        appendAPIAtrbt(searchCriteria)
        const emailData = await getEmailDetails(searchCriteria);
        dispatch({ type: "SET_EMAIL_DATA", payload: emailData.data });
        const pageCount = Math.ceil(emailData.totalCount / 10);
        dispatch({ type: "SET_API_TOTAL_PAGE", payload: pageCount });
      } else if (activeTab === "SMS") {
        if (searchLoanNumber) {
          searchCriteria.loanAccountNumber = searchLoanNumber;
          searchCriteria.offset = apiOffset;
          searchCriteria.limit = apiLimit;
          if(searchLoanNumber==='True'){
            delete searchCriteria.loanAccountNumber
            if (LoanNumber) searchCriteria.loanAccountNumber = LoanNumber;
            if (searchMobileNumber) searchCriteria.mobileNumber = searchMobileNumber;
            if (!searchMobileNumber) searchCriteria.mobileNumber = loanData?.mobile_number;
            if (searchPANNumber) searchCriteria.panNumber = searchPANNumber;
            searchCriteria.all = 'True'
          }
        }
        delete searchCriteria.emailId
        appendAPIAtrbt(searchCriteria)
        const smsData = await getSmsDetails(searchCriteria);
        dispatch({ type: "SET_SMS_DATA", payload: smsData?.data});
        const pageCount = Math.ceil(smsData?.totalCount / 10);
        dispatch({ type: "SET_API_TOTAL_PAGE", payload: pageCount });
      } else if (activeTab === "All") {
        if (searchLoanNumber) {
          searchCriteria.loanAccountNumber = searchLoanNumber;
          searchCriteria.offset = apiOffset;
          searchCriteria.limit = apiLimit;
          if(searchLoanNumber==='True'){
            delete searchCriteria.loanAccountNumber
            if (LoanNumber) searchCriteria.loanAccountNumber = LoanNumber;
            if (searchMobileNumber) searchCriteria.mobileNumber = searchMobileNumber;
            if (!searchMobileNumber) searchCriteria.mobileNumber = loanData?.mobile_number;
            if (searchEmail) searchCriteria.emailId = searchEmail;
            if (!searchEmail) searchCriteria.emailId = loanData.email_id;
            if (searchPANNumber) searchCriteria.panNumber = searchPANNumber;
            searchCriteria.all = 'True'
          }
        }
        appendAPIAtrbt(searchCriteria)
        const allData = await getAllDetails(searchCriteria);
        dispatch({ type: "SET_ALL_DATA", payload: allData?.data });
        const pageCount = Math.ceil(allData?.totalCount / 10);
        dispatch({ type: "SET_API_TOTAL_PAGE", payload: pageCount });
      }
    } catch (error) {
      console.error("Error fetching data---------------------", error);
      dispatch({ type: "SET_EMAIL_DATA", payload: [] });
      dispatch({ type: "SET_ALL_DATA", payload: [] });
      dispatch({ type: "SET_SMS_DATA", payload: []});
    } finally {
      dispatch({ type: "SET_IS_LOADING", payload: false });
    }
  };
  const handleSearchLoanNumberChange = (newSearchLoanNumber) => {
    dispatch({ type: "SET_SEARCH_LOAN_NUMBER", payload: newSearchLoanNumber });
  };

  const handleSearchMobileNumberChange = (newSearchMobileNumber) => {
    dispatch({
      type: "SET_SEARCH_MOBILE_NUMBER",
      payload: newSearchMobileNumber,
    });
  };

  const handleSearchEmailChange = (newSearchEmail) => {
    dispatch({ type: "SET_SEARCH_EMAIL", payload: newSearchEmail });
  };

  const handleSearchPANNumberChange = (newSearchPANNumber) => {
    dispatch({ type: "SET_SEARCH_PAN_NUMBER", payload: newSearchPANNumber?.toUpperCase() });
  };
  const setLoanNumber = (newLoanNumber) => {
    dispatch({ type: "SET_LOAN_NUMBER", payload: newLoanNumber });
  };
  const handleSearchButtonClick = async () => {
    try {
  
      let searchFlag = true;
      
      if (searchMobileNumber && !REGX.CONTACTNUMBER.test(searchMobileNumber)) {
        searchFlag = false;
        dispatch({
          type: "SET_MOBILE_ERROR",
          payload: "Please Enter Valid Mobile Number.",
        });
          } else {
            dispatch({ type: "SET_MOBILE_ERROR", payload: "" });
          }
      // Commented to remove PAN NUMBER Search Functionality this can be uncommented in future if required
      /* if (
        searchPANNumber &&
        !REGX.PAN_CARD.test(searchPANNumber)
        ) {
          searchFlag = false;
          dispatch({
            type: "SET_PAN_ERROR",
            payload: "Please enter  valid PAN number.",
          });
        } else {
          dispatch({ type: "SET_PAN_ERROR", payload: "" });
        } */
        
        /* if (searchEmail && !REGX.EMAIL_REGX.test(searchEmail)) {
          searchFlag = false;
          dispatch({
            type: "SET_EMAIL_ERROR",
            payload: "Please enter a valid email address.",
          });

        } else {
          dispatch({ type: "SET_EMAIL_ERROR", payload: "" });
        } */
        
        if (searchEmail) {
          dispatch({ type: "SET_EMAIL_ERROR", payload: "" });
        }
        
        if (LoanNumber && !REGX.LOAN_REGX.test(LoanNumber)) {
          searchFlag = false;
          dispatch({
            type: "SET_LOAN_NUMBER_ERROR",
            payload: "Please enter an alphanumeric value.",
          });
        } else {;
          dispatch({ type: "SET_LOAN_NUMBER_ERROR", payload: "" });
        }
        if (
          !LoanNumber &&
        !searchMobileNumber &&
        !searchEmail &&
        !searchPANNumber
        ) {
          toast.error("Please enter at least one field", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 500,
          });
        }else{
        // Create search criteria
        if(searchFlag){
          dispatch({ type: "SET_IS_SEARCHLOADING", payload: true });
          let searchCriteria = {};
          if (LoanNumber) searchCriteria.loanAccountNumber = LoanNumber;
          if (searchMobileNumber) searchCriteria.mobileNumber = searchMobileNumber;
          if (searchEmail) searchCriteria.emailId = searchEmail;
          if (searchPANNumber) searchCriteria.panNumber = searchPANNumber;
          if(LoanNumber ||
        searchMobileNumber ||
        searchEmail ||
        searchPANNumber){
        let data = await getUserDetails(searchCriteria);
      if (data != null) {
          dispatch({ type: "SET_SEARCH_LOAN_NUMBER", payload: null });
          const filterOptions = data[0].loanAccountDropDownList
            .sort((a, b) => (b.value > a.value ? 1 : -1))
            .filter((option) =>
              LoanNumber && option.value === 'True' ? null : option
            );
          setDropdownData(filterOptions || []);
          dispatch({ type: 'SET_LOAN_DATA', payload: data[0] });
          dispatch({
            type: 'SET_SEARCH_LOAN_NUMBER',
            payload: filterOptions[0].value,
          });
      } else {
        // Update state with the received data
        dispatch({ type: "SET_LOAN_DATA", payload: null });
        dispatch({ type: "SET_ALL_DATA", payload: null });
        dispatch({ type: "SET_SMS_DATA", payload: null });
        dispatch({ type: "SET_EMAIL_DATA", payload: null });
        setDropdownData([]);
        // dispatch({ type: "SET_SEARCH_LOAN_NUMBER", payload: null });
        fetchData()
      }}
    }
  }
      // Additional logic based on activeTab or other conditions can be added here
    } catch (error) {
      dispatch({ type: "SET_LOAN_DATA", payload: null });
      dispatch({ type: "SET_ALL_DATA", payload: null });
      dispatch({ type: "SET_SMS_DATA", payload: null });
      dispatch({ type: "SET_EMAIL_DATA", payload: null });
      dispatch({ type: "SET_SEARCH_LOAN_NUMBER", payload: null });
      console.error("Error fetching data:", error);
      setDropdownData([]);
      // Handle the error as needed
    } finally {
      dispatch({ type: "SET_IS_SEARCHLOADING", payload: false });
      dispatch({ type: "SET_IS_LOADING", payload: false });
    }
  };

  useEffect(() => {
      fetchData();
  }, [searchLoanNumber,loanData]);
  useEffect(() => {
    if(apiOffset){
      fetchData();
    }
  }, [apiOffset]);

  const handleDropdownChange = async (category, prevOption, selectedOption) => {
    // Handle the selected option based on the category (Type of Communication, Source System, Vendor Name)
   
    dispatch({ type: "SET_API_OFFSET", payload: 0 });
    let searchCriteria = {};
    if (searchLoanNumber) {
      searchCriteria.loanAccountNumber = searchLoanNumber;
      searchCriteria.offset = apiOffset;
      searchCriteria.limit = apiLimit;
    }
    if (startDate) {
      searchCriteria.startDate = startDate;
      searchCriteria.offset = apiOffset;
      searchCriteria.limit = apiLimit;
    }
    if (endDate) {
      searchCriteria.endDate = endDate;
      searchCriteria.offset = apiOffset;
      searchCriteria.limit = apiLimit;
    }
    if (communicationType) {
      searchCriteria.typeOfCommunication = communicationType;
      searchCriteria.offset = apiOffset;
      searchCriteria.limit = apiLimit;
    }
    if (sourceSystem) {
      searchCriteria.sourceSystem = sourceSystem;
      searchCriteria.offset = apiOffset;
      searchCriteria.limit = apiLimit;
    }
    if (vendorName) {
      searchCriteria.vendorName = vendorName;
      searchCriteria.offset = apiOffset;
      searchCriteria.limit = apiLimit;
    }
    switch (category) {
      case "Type of Communication":
        dispatch({
          type: "SET_COMMUNICATION_TYPE",
          payload: selectedOption.typeOfCommunication,
        });
        searchCriteria.typeOfCommunication = selectedOption.typeOfCommunication;
        break;
      case "Source System":
        dispatch({
          type: "SET_SOURCE_SYSTEM",
          payload: selectedOption.sourceSystem,
        });
        searchCriteria.sourceSystem = selectedOption.sourceSystem;
        break;
      case "Vendor Name":
        dispatch({
          type: "SET_VENDOR_NAME",
          payload: selectedOption.vendorName,
        });
        searchCriteria.vendorName = selectedOption.vendorName;
        break;
      default:
        break;
    }

    try {
      dispatch({ type: "SET_IS_LOADING", payload: true });
      if (activeTab === "Email") {

        if(searchLoanNumber==='True'){
          delete searchCriteria.loanAccountNumber
          if (LoanNumber) searchCriteria.loanAccountNumber = LoanNumber;
          if (searchEmail) searchCriteria.emailId = searchEmail;
          if (!searchEmail) searchCriteria.emailId = loanData?.email_id;
          if (searchPANNumber) searchCriteria.panNumber = searchPANNumber;
          searchCriteria.all = 'True'
        }
        else {
          if (searchEmail) searchCriteria.emailId = searchEmail;
        }
        delete searchCriteria.mobileNumber
        const data = await getEmailDetails(searchCriteria);
        dispatch({ type: "SET_EMAIL_DATA", payload: data.data });
        const pageCount = Math.ceil(data.totalCount / 10);
        dispatch({ type: "SET_API_TOTAL_PAGE", payload: pageCount });
      
      } else if (activeTab === "SMS") {

        if(searchLoanNumber==='True'){
          delete searchCriteria.loanAccountNumber
          if (LoanNumber) searchCriteria.loanAccountNumber = LoanNumber;
          if (searchMobileNumber) searchCriteria.mobileNumber = searchMobileNumber;
          if (!searchMobileNumber) searchCriteria.mobileNumber = loanData?.mobile_number;
          if (searchPANNumber) searchCriteria.panNumber = searchPANNumber;
          searchCriteria.all = 'True'
        }
        else {
          if (searchMobileNumber) searchCriteria.mobileNumber = searchMobileNumber;
        }
        delete searchCriteria.emailId
        const data = await getSmsDetails(searchCriteria);
        dispatch({ type: "SET_SMS_DATA", payload: data.data });
        const pageCount = Math.ceil(data.totalCount / 10);
        dispatch({ type: "SET_API_TOTAL_PAGE", payload: pageCount });
      
      } else if (activeTab === "All") {
        
        if(searchLoanNumber==='True'){
          delete searchCriteria.loanAccountNumber
          if (LoanNumber) searchCriteria.loanAccountNumber = LoanNumber;
          if (searchMobileNumber) searchCriteria.mobileNumber = searchMobileNumber;
          if (!searchMobileNumber) searchCriteria.mobileNumber = loanData?.mobile_number;
          if (searchEmail) searchCriteria.emailId = searchEmail;
          if (!searchEmail) searchCriteria.emailId = loanData?.email_id;
          if (searchPANNumber) searchCriteria.panNumber = searchPANNumber;
          searchCriteria.all = 'True'
        }
        else {
          if (searchMobileNumber) searchCriteria.mobileNumber = searchMobileNumber;
          if (searchEmail) searchCriteria.emailId = searchEmail;
        }

        const data = await getAllDetails(searchCriteria);
        dispatch({ type: "SET_ALL_DATA", payload: data.data });
        const pageCount = Math.ceil(data?.totalCount / 10);
        dispatch({ type: "SET_API_TOTAL_PAGE", payload: pageCount });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      dispatch({ type: "SET_EMAIL_DATA", payload: [] });
      dispatch({ type: "SET_ALL_DATA", payload: [] });
      dispatch({ type: "SET_SMS_DATA", payload: []});
    } finally {
      dispatch({ type: "SET_IS_LOADING", payload: false });
    }
  };

  useEffect(() => {
      const fetchcommunicationOptionData = async () => {
        try {
          let data = await getCommunicationDropdown();

          dispatch({ type: "SET_COMMUNICATION_OPTIONS", payload: data });
        } catch (error) {
          console.error("Error fetching communication data:", error);
        }
      };
      const fetchsourceOptionData = async () => {
        try {
          let data = await getSourceDropdown();
          dispatch({ type: "SET_SOURCE_OPTIONS", payload: data });
        } catch (error) {
          console.error("Error fetching source data:", error);
        }
      };
      
      const fetchvenderOptionData = async () => {
        try {
          let data = await getVenderDropdown();

          dispatch({ type: "SET_VENDER_OPTIONS", payload: data });
        } catch (error) {
          console.error("Error fetching vender data:", error);
        }
      };

      const fetchDeliveryStatusesData = async () => {
        try {
          let data = await getDeliveryStatuses(); // Linked to Get Delivery Status API
          setDSCombArr(data)
        } catch (error) {
          console.error('Error fetching delivery statuses:', error);
          throw error;
        }
      };
      
      fetchcommunicationOptionData();
      fetchsourceOptionData();
      fetchvenderOptionData();
      fetchDeliveryStatusesData();
    }, []);

  const communicationDropdownRef = useRef(null);
  const sourceSystemDropdownRef = useRef(null);
  const vendorNameDropdownRef = useRef(null);


  const handleChange = (selectedOption) => {
    handleSearchLoanNumberChange(selectedOption);
  };

  
  const handelClearLoannumber = () => {

    dispatch({ type: "SET_SEARCH_LOAN_NUMBER", payload: "" });
    dispatch({ type: "SET_LOAN_DATA", payload: null });
    dispatch({ type: "SET_SEARCH_LOAN_NUMBER", payload: null });
    dispatch({
      type: "SET_SEARCH_MOBILE_NUMBER",
      payload: "",
    });
    dispatch({ type: "SET_SEARCH_PAN_NUMBER", payload: "" });
    dispatch({ type: "SET_SEARCH_EMAIL", payload: "" });

    dispatch({ type: "SET_ALL_DATA", payload: null });
    dispatch({ type: "SET_EMAIL_DATA", payload: null });
    dispatch({ type: "SET_SMS_DATA", payload: null });
    dispatch({ type: "CLEAR_ALL", payload: !clearAll });
    setDropdownData([])
    
  };

  const handleDownload = async(propURL) => {
    try{
      /* Here an authorization header is set with authentication token value to authorize download of excel file. */
      const response = await fetch(
        propURL,
        { 
          headers: {
            Authorization: `Basic ${process.env.REACT_APP_BASIC_AUTH}`,
          },
        }
      )

      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;

      console.log("searchLoanNumber : ", searchLoanNumber);
      console.log("searchMobileNumber : ", searchMobileNumber);
      console.log("searchEmail : ", searchEmail);

      
      let filename = "";
      if (searchLoanNumber !== "" && searchLoanNumber !== 'True') {
        console.log("searchLoanNumber if : ", searchLoanNumber);
        filename += (filename ? "_" : "") + searchLoanNumber;
      }
      if (searchMobileNumber !== "") {
        console.log("searchMobileNumber if : ", searchMobileNumber);
        filename += (filename ? "_" : "") + searchMobileNumber;
      }
      if (searchEmail !== "") {
        console.log("searchEmail if : ", searchEmail);
        filename += (filename ? "_" : "") + `[${searchEmail}]`;
      }
      if (filename) {
        link.setAttribute("download", `${filename}.xlsx`);
      }
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error downloading file: ', error);
    }
  }

  const downloadData = async () => {
    try {
      if(loanData?.loanAccountDropDownList || loanData === null){
      let url = `${baseURL}${API_URLS.COMMUNICATION_TIMELINE.TIMELINE_DOWNLOAD}?`;
      if (searchLoanNumber) url += `loanAccountNumber=${searchLoanNumber}&`;
      if (searchPANNumber) url += `panNumber=${searchPANNumber}&`;
      if (searchEmail) url += `emailId=${searchEmail}&`;
      if (searchMobileNumber) url += `mobileNumber=${searchMobileNumber}&`;
      if (startDate) url += `startDate=${startDate}&`;
      if (endDate) url += `endDate=${endDate}&`;
      if (communicationType) url += `typeOfCommunication=${communicationType}&`;
      if (sourceSystem) url += `sourceSystem=${sourceSystem}&`;
      if (vendorName) url += `vendorName=${vendorName}&`;
      if (loanData?.account_holder)
        url += `CustomerName=${loanData?.account_holder}`;
      url = url.replace(/&$/, "");
      handleDownload(url);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };
  

  const handleDateRangeChange = async (start, end) => {
    
    dispatch({ type: "SET_START_DATE", payload: start });
    dispatch({ type: "SET_END_DATE", payload: end });

    let searchCriteria = {
      ...(searchLoanNumber && {
        loanAccountNumber: searchLoanNumber,
        limit: apiLimit,
        offset: apiOffset,
      }),
      ...(start && { startDate: start }),
      ...(end && { endDate: end }),
      ...(communicationType && { typeOfCommunication: communicationType }),
      ...(sourceSystem && { sourceSystem: sourceSystem }),
      ...(vendorName && { vendorName: vendorName }),
    };
    if(searchLoanNumber==='True'){
      delete searchCriteria.loanAccountNumber
      if (LoanNumber) searchCriteria.loanAccountNumber = LoanNumber;
      if (searchEmail) searchCriteria.emailId = searchEmail;
      if (!searchEmail) searchCriteria.emailId = loanData?.email_id;
      if (searchMobileNumber) searchCriteria.mobileNumber = searchMobileNumber;
      if (!searchMobileNumber) searchCriteria.mobileNumber = loanData?.mobile_number;
      if (searchPANNumber) searchCriteria.panNumber = searchPANNumber;
      searchCriteria.all = 'True'
    }
    appendAPIAtrbt(searchCriteria)
    try {
      dispatch({ type: "SET_IS_LOADING", payload: true });
      if (activeTab === "Email") {
        delete searchCriteria.mobileNumber
        const data = await getEmailDetails(searchCriteria);
        if(data){
          dispatch({ type: "SET_EMAIL_DATA", payload: data.data });
          const pageCount = Math.ceil(data.totalCount / 10);
          dispatch({ type: "SET_API_TOTAL_PAGE", payload: pageCount });
        }else{
          dispatch({ type: "SET_EMAIL_DATA", payload: null });
          //const pageCount = Math.ceil(data.totalCount / 10);
          //dispatch({ type: "SET_API_TOTAL_PAGE", payload: pageCount });
        }
        
        const pageCount = Math.ceil(data.totalCount / 10);
        dispatch({ type: "SET_API_TOTAL_PAGE", payload: pageCount });
      } else if (activeTab === "SMS") {
        delete searchCriteria.emailId
        const data = await getSmsDetails(searchCriteria);
        if(data){
          dispatch({ type: "SET_SMS_DATA", payload: data.data });
          const pageCount = Math.ceil(data.totalCount / 10);
          dispatch({ type: "SET_API_TOTAL_PAGE", payload: pageCount });
        }else{
          dispatch({ type: "SET_SMS_DATA", payload: null });
          //const pageCount = Math.ceil(data.totalCount / 10);
          //dispatch({ type: "SET_API_TOTAL_PAGE", payload: pageCount });
        }
        
      } else if (activeTab === "All") {
        const data = await getAllDetails(searchCriteria);
        if(data){
          dispatch({ type: "SET_ALL_DATA", payload: data.data });
          const pageCount = Math.ceil(data.totalCount / 10);
          dispatch({ type: "SET_API_TOTAL_PAGE", payload: pageCount });
        }else{
          dispatch({ type: "SET_ALL_DATA", payload: null });
          //const pageCount = Math.ceil(data.totalCount / 10);
          //dispatch({ type: "SET_API_TOTAL_PAGE", payload: pageCount });
        }
        
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      dispatch({ type: "SET_EMAIL_DATA", payload: [] });
      dispatch({ type: "SET_ALL_DATA", payload: [] });
      dispatch({ type: "SET_SMS_DATA", payload: []});
    } finally {
      dispatch({ type: "SET_IS_LOADING", payload: false });
    }
  };

  

  const clearSidebarFilters = async () => {
    // Now call resetSelectedValue for each DropdownSideBar to reset their values
    dispatch({ type: "SET_API_OFFSET", payload: 0 });
 
    communicationDropdownRef.current.resetSelectedValue();
    sourceSystemDropdownRef.current.resetSelectedValue();
    vendorNameDropdownRef.current.resetSelectedValue();
    dispatch({ type: "RESET_FILTERS" });
    // Use the current state values
 
    let searchCriteria = {};
    if (searchLoanNumber) {
      searchCriteria.loanAccountNumber = searchLoanNumber;
      searchCriteria.offset = apiOffset;
      searchCriteria.limit = apiLimit;
      if(searchLoanNumber==='True'){
        delete searchCriteria.loanAccountNumber
        if (LoanNumber) searchCriteria.loanAccountNumber = LoanNumber;
        if (searchMobileNumber) searchCriteria.mobileNumber = searchMobileNumber;
        if (!searchMobileNumber) searchCriteria.mobileNumber = loanData?.mobile_number;
        if (searchEmail) searchCriteria.emailId = searchEmail;
        if (!searchEmail) searchCriteria.emailId = loanData?.email_id;
        if (searchPANNumber) searchCriteria.panNumber = searchPANNumber;
        searchCriteria.all = 'True'
      }
    }
    else{
      if (searchMobileNumber) searchCriteria.mobileNumber = searchMobileNumber;
        // if (!searchMobileNumber) searchCriteria.mobileNumber = loanData?.mobile_number;
        if (searchEmail) searchCriteria.emailId = searchEmail;
        // if (!searchEmail) searchCriteria.emailId = loanData?.email_id;
        if (searchPANNumber) searchCriteria.panNumber = searchPANNumber;
    }
    if (startDate) {
      searchCriteria.startDate = startDate;
      searchCriteria.offset = apiOffset;
      searchCriteria.limit = apiLimit;
    }
    if (endDate) {
      searchCriteria.endDate = endDate;
      searchCriteria.offset = apiOffset;
      searchCriteria.limit = apiLimit;
    }
    // Make an API call using the current state valuesclearSidebarFilters
    try {
      dispatch({ type: "SET_IS_LOADING", payload: true });
 
      if (activeTab === "Email") {
        delete searchCriteria.mobileNumber
        const data = await getEmailDetails(searchCriteria);
        dispatch({ type: "SET_EMAIL_DATA", payload: data.data });
        const pageCount = Math.ceil(data.totalCount / 10);
        dispatch({ type: "SET_API_TOTAL_PAGE", payload: pageCount });
      } else if (activeTab === "SMS") {
        delete searchCriteria.emailId
        const data = await getSmsDetails(searchCriteria);
        dispatch({ type: "SET_SMS_DATA", payload: data.data });
        const pageCount = Math.ceil(data.totalCount / 10);
        dispatch({ type: "SET_API_TOTAL_PAGE", payload: pageCount });
      } else if (activeTab === "All") {
        const data = await getAllDetails(searchCriteria);
        dispatch({ type: "SET_ALL_DATA", payload: data.data });
        const pageCount = Math.ceil(data.totalCount / 10);
        dispatch({ type: "SET_API_TOTAL_PAGE", payload: pageCount });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle the error as needed
    } finally {
      dispatch({ type: "SET_IS_LOADING", payload: false });
    }
  };
  const handleTabChange = async (newTab) => {
  
    dispatch({ type: "SET_ACTIVE_TAB", payload: newTab });
    dispatch({ type: "SET_API_OFFSET", payload: 0 });

    // Make API calls based on the selected tab
    try {
      dispatch({ type: "SET_IS_LOADING", payload: true });
      let searchCriteria = {};

      if (searchLoanNumber) {
        searchCriteria.loanAccountNumber = searchLoanNumber;
        searchCriteria.offset = apiOffset;
        searchCriteria.limit = apiLimit;
        if(searchLoanNumber==='True'){
          delete searchCriteria.loanAccountNumber
          if (LoanNumber) searchCriteria.loanAccountNumber = LoanNumber;
          if (searchMobileNumber) searchCriteria.mobileNumber = searchMobileNumber;
          if (!searchMobileNumber) searchCriteria.mobileNumber = loanData?.mobile_number;
          if (searchEmail) searchCriteria.emailId = searchEmail;
          if (!searchEmail) searchCriteria.emailId = loanData?.email_id;
          if (searchPANNumber) searchCriteria.panNumber = searchPANNumber;
          searchCriteria.all = 'True'
        }
      }
      appendAPIAtrbt(searchCriteria)
      if (startDate) searchCriteria.startDate = startDate;
      if (endDate) searchCriteria.endDate = endDate;
      if (communicationType)
        searchCriteria.typeOfCommunication = communicationType;
      if (sourceSystem) searchCriteria.sourceSystem = sourceSystem;
      if (vendorName) searchCriteria.vendorName = vendorName;

      let data;
      let pageCount;
      if(loanData?.loanAccountDropDownList || loanData === null ){
      switch (newTab) {
        case "All":
          data = await getAllDetails(searchCriteria);
          dispatch({ type: "SET_ALL_DATA", payload: data.data });
          pageCount = Math.ceil(data.totalCount / 10);
          dispatch({ type: "SET_API_TOTAL_PAGE", payload: pageCount });
          break;
        case "Email":
          delete searchCriteria.mobileNumber
          data = await getEmailDetails(searchCriteria);
          dispatch({ type: "SET_EMAIL_DATA", payload: data.data });
          pageCount = Math.ceil(data.totalCount / 10);
          dispatch({ type: "SET_API_TOTAL_PAGE", payload: pageCount });
          break;
        case "SMS":
          delete searchCriteria.emailId
          data = await getSmsDetails(searchCriteria);
          dispatch({ type: "SET_SMS_DATA", payload: data.data });
          pageCount = Math.ceil(data.totalCount / 10);
          dispatch({ type: "SET_API_TOTAL_PAGE", payload: pageCount });
          break;
        // Add cases for other tabs as needed
        default:
          break;
      }
    }
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle the error as needed
    } finally {
      dispatch({ type: "SET_IS_LOADING", payload: false });
    }
  };

  const handelPagination = () => {
    dispatch({ type: "SET_API_CURRENT_PAGE", payload: currentPage + 1 });
    dispatch({ type: "SET_API_OFFSET", payload: apiOffset + 10 });
  };

  const handelUpPagination = () => {
    dispatch({ type: "SET_API_CURRENT_PAGE", payload: currentPage - 1 });
    dispatch({ type: "SET_API_OFFSET", payload: apiOffset - 10 });
  };
  const renderDropdown = useCallback(() => {
    return (
      <Dropdown
        options={
          dropdownData ||[]
        }
        onSelect={handleChange}
      />
    );
  }, [dropdownData]);
  const handleKeyPress = (event) => {
    if (event.keyCode === 13 || event.which === 13) {
      handleSearchButtonClick();
     }

  }
  return (
    <div className={style.mainwrapper}>
      <div className={style.searcwrapper}>
        <div className={style.search}>
          <SearchBox
             handleKey={handleKeyPress}
            placeHolder="Loan Account Number"
            inputtype="text"
            onSearchChange={setLoanNumber}
            errorMessage={loanNumberError}
            isClicked={clearAll}
          />
        </div>
        <div className={style.search}>
          <SearchBox
           handleKey={handleKeyPress}
            placeHolder="Mobile Number"
            inputtype="text"
            onSearchChange={handleSearchMobileNumberChange}
            errorMessage={mobileError}
            isClicked={clearAll}
          />
        </div>
        <div className={style.search}>
          <SearchBox
           handleKey={handleKeyPress}
            placeHolder="Email ID"
            inputtype="email"
            onSearchChange={handleSearchEmailChange}
            errorMessage={emailError}
            isClicked={clearAll}
          />
        </div>
        {/* Commented to remove PAN NUMBER Search Functionality this can be uncommented in future if required */}
        {/* <div className={style.search}>
          <SearchBox
            handleKey={handleKeyPress}
            placeHolder="PAN Number"
            inputtype="text"
            onSearchChange={handleSearchPANNumberChange}
            errorMessage={panError}
            isPan={true}
            isClicked={clearAll}
          />
        </div> */}
        <div className={style.search}>
          <CustomButton
            buttonName={
              searchButtonLoading ? <Loader customstyle={true} /> : "Search"
            }
            onClick={handleSearchButtonClick}
          />
        </div>
      </div>
      <div className={style.dropdownDateDownload}>
        <div className={style.leftside}>
          <div className={style.customerNameMain}>
            <div className={style.profileImage}>
              <img src={ProfileImage} alt="ProfileImg"></img>
            </div>
            <div className={style.accountName}>
              {loanData?.account_holder
                ? loanData?.account_holder
                : "Account Holder Name"}
              <span> {"-"}</span>
            </div>
            {renderDropdown()}
          </div>
          <div>
            <a className='clear_filter pt-1' onClick={handelClearLoannumber}>
              Clear
            </a>
          </div>
        </div>
        <div className={style.rightside}>
          <CustomDatePickerRange onDateRangeChange={handleDateRangeChange} />
          <div className={style.downloadButtonMain} onClick={downloadData}>
            <div className={style.downloadText}>Download CSV</div>
            <div className={style.downloadImg}>
              <img src={Download} alt="Dwn"></img>
            </div>
          </div>
        </div>
      </div>
      <div className={style.bottomMain}>
        <div className={style.leftBottomMenu}>
          <div className={style.dropdownWrapper}>
            <DropdownSideBar
              options={communicationOptions}
              category="Type of Communication"
              defaultLabel="Type of Communication"
              value={selectedValue}
              id="communicationDropdown"
              ref={communicationDropdownRef}
              onDropdownChange={handleDropdownChange}
            />
          </div>
          <div className={style.dropdownWrapper}>
            <DropdownSideBar
              options={sourceOptions}
              category="Source System"
              defaultLabel="Source System"
              value={selectedValue}
              id="sourceSystemDropdown"
              ref={sourceSystemDropdownRef}
              onDropdownChange={handleDropdownChange}
            />
          </div>
          <div className={style.dropdownWrapper}>
            <DropdownSideBar
              options={venderOptions}
              category="Vendor Name"
              defaultLabel="Vendor Name"
              value={selectedValue}
              id="vendorNameDropdown"
              ref={vendorNameDropdownRef}
              onDropdownChange={handleDropdownChange}
            />
          </div>
          <div className={style.clear_filter} onClick={clearSidebarFilters}>
            Clear
          </div>
        </div>
        <div className={style.righBottomtside}>
          <DashboardNavigation onTabChange={handleTabChange}>
            <div label="All">
              <div className={style.lineContent}>
                {isLoading ? (
                  <Loader />
                ) : allData && allData.length > 0 && allData != null ? (
                  <>
                    <Timeline
                      data={allData || []}
                      activeTab="All"
                      customerName={loanData?.account_holder}
                      DSCombArrProp={DSCombArr}
                    />
                    <div className="d-flex justify-content-end">
                    {/* {currentPage < totalCount ? 
                    <div
                      className={style.pagination_button}
                      style={{left:'50%',width:'100px','margin-right':'41%', position: 'absolute'}}
                      onClick={handelPagination}
                      >
                      See more
                    </div> : ""} */}
                    {currentPage > 1 ? 
                    <div
                      className={style.pagination_button}
                      onClick={handelUpPagination}
                      >
                      <img src={UpwardImage} alt="UpwardImg"></img>
                    </div> : ""}
                      </div>
                  </>
                ) : (
                  <DataNoFound />
                )}
              </div>
            </div>
            <div label="Email">
              <div className={style.lineContent}>
                {isLoading ? (
                  <Loader />
                ) : emailData && emailData.length > 0 && emailData != null ? (
                  <>
                    <Timeline
                      data={emailData || []}
                      activeTab="Email"
                      customerName={loanData?.account_holder}
                      DSCombArrProp={DSCombArr}
                    />
                    <div className="d-flex justify-content-end">
                    {/* {currentPage < totalCount ? 
                    <div
                      className={style.pagination_button}
                      style={{left:'50%',width:'100px','margin-right':'41%', position: 'absolute'}}
                      onClick={handelPagination}
                      >
                      See more
                    </div> : ""} */}
                    {currentPage > 1 ? 
                    <div
                      className={style.pagination_button}
                      onClick={handelUpPagination}
                      >
                      <img src={UpwardImage} alt="UpwardImg"></img>
                    </div> : ""}
                      </div>
                  </>
                ) : (
                  <DataNoFound />
                )}
              </div>
            </div>
            <div label="SMS">
              <div className={style.lineContent}>
                {isLoading ? (
                  <Loader />
                ) : smsData && smsData.length > 0 && smsData != null ? (
                  <>
                    <Timeline
                      data={smsData || []}
                      activeTab="SMS"
                      customerName={loanData?.account_holder}
                      DSCombArrProp={DSCombArr}
                    />
                    <div className="d-flex justify-content-end">
                    {/* {currentPage < totalCount ? 
                    <div
                      className={style.pagination_button}
                      style={{left:'50%',width:'100px','margin-right':'41%', position: 'absolute'}}
                      onClick={handelPagination}
                      >
                      See more
                    </div> : ""} */}
                    {currentPage > 1 ? 
                    <div
                      className={style.pagination_button}
                      onClick={handelUpPagination}
                      >
                      <img src={UpwardImage} alt="UpwardImg"></img>
                    </div> : ""}
                      </div>
                  </>
                ) : (
                  <DataNoFound />
                )}
              </div>
            </div>
            <div label="WhatsApp">
              <div className={style.lineContent}>
                <Timeline
                  data="WhatsApp"
                  activeTab="WhatsApp"
                  customerName={loanData?.account_holder}
                  DSCombArrProp={DSCombArr}
                />
              </div>
            </div>
            <div label="Post">
              <div className={style.lineContent}>
                <Timeline
                  data="Post"
                  activeTab="Post"
                  customerName={loanData?.account_holder}
                  DSCombArrProp={DSCombArr}
                />
              </div>
            </div>
          </DashboardNavigation>
        </div>
      </div>
    </div>
  );
};

export default CommunicationTimeline;
