import React from "react";
import style from "../styles/DataNotFound/dataNotFound.module.scss";
const DataNoFound = () => {
  return (
    <>
      <div className={style.mainwrapper}>
        <div>Data Not Available</div>
        <div className={style.rightTimelinediv}>
          <div
            className={style.timelineMode}
            style={{ backgroundColor: "#BC7CD9" }}
          >
            Email
          </div>
          <div
            className={style.timelineMode}
            style={{ backgroundColor: "#0655B4" }}
          >
            SMS
          </div>
          <div
            className={style.timelineMode}
            style={{ backgroundColor: "#D3D3D3" }}
          >
            WhatsApp
          </div>
          <div
            className={style.timelineMode}
            style={{ backgroundColor: "#D3D3D3" }}
          >
            Post
          </div>
        </div>
      </div>
    </>
  );
};

export default DataNoFound;
