import React, { useState } from "react";
import style from "../../styles/Profile/profile.module.scss"
import profilepic from "../../images/profilepic.png"
import TextBox from "../../common/TextBox";
import CustomButton from "../../common/CustomButton";
import CancelButton from "../../common/CancelButton";
const Profile =()=>{
    const[firstname,setFirstName]=useState('');
    const[lastname,setLastName]=useState('');
    const [email, setEmail] = useState('');
    const [contact, setContact] = useState('');
    const [access, setAccess] = useState('');
    const [location, setLocation] = useState('');
    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
      };
      const handleLastNameChange = (event) => {
        setLastName(event.target.value);
      };
      const handleEmailChange = (event) => {
        setEmail(event.target.value);
      };
      const handleContactChange = (event) => {
        setContact(event.target.value);
      };
      const handleAccessChange = (event) => {
        setAccess(event.target.value);
      };
      const handleLocationsChange = (event) => {
        setLocation(event.target.value);
      };
    return(
    <div className={style.container}>
        <img  className={style.profilepic}src={profilepic}></img>
        <div className={style.profilebox}>
        <div className="row m-1 p-2 pb-5">
                <div className="col-md-6 px-1"style={{ marginBottom: '20px' ,marginLeft:'0px'}}>
                  <small className={style.label}>First name</small>
                  <small className="fieldLabel text-danger ml-1">*</small>
                  <TextBox
                   value={firstname} 
                   onChange={ handleFirstNameChange} 
                  />
                </div>
                <div className="col-md-6 px-1"style={{ marginBottom: '20px', }}>
                  <small className={style.label}>Last Name</small>
                  <small className="fieldLabel text-danger ml-1">*</small>
                  <TextBox
                    value={lastname} 
                    onChange={ handleLastNameChange} 
                  />
                </div>
                <div className="col-md-6 px-1 " style={{ marginBottom: '20px' }}>
                  <small className={style.label}>Email Id</small>
                  <small className="fieldLabel text-danger ml-1">*</small>
                  <TextBox
                    value={email} 
                    onChange={ handleEmailChange}
                 />
               </div>
                <div className="col-md-6 px-1" style={{ marginBottom: '20px' }}>
                  <small className={style.label}>Contact No</small>
                  <small className="fieldLabel text-danger ml-1">*</small>
                  <TextBox
                    value={contact} 
                    onChange={handleContactChange}
                 />
                </div>
               
                <div className="col-md-6 px-1" style={{ marginBottom: '20px' }}>
                  <small className={style.label}>Access</small>
                  <small className="fieldLabel text-danger ml-1">*</small>
                  <TextBox
                    value={access} 
                    onChange={handleAccessChange}
                  />     
                </div>
                <div className="col-md-6 px-1"style={{ marginBottom: '20px' }}>
                  <small className={style.label}>Location</small>
                  <small className="fieldLabel text-danger ml-1">*</small>
                  <TextBox
                   value={location} 
                   onChange={ handleLocationsChange}
                 />
                </div>
               
                <div className={style.buttons} >
                    <CustomButton buttonName={"Save"}/>
                    <CancelButton buttonName={'Close'}/>
                </div>
              </div>
              </div>
    </div>
    )
}
export default Profile;