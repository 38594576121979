import React, { useEffect, useReducer, useState } from "react";
import MessageDelivered from "../../images/MsgGreen.png";
import MessageNotDelivered from "../../images/MsgRed.png";
import CloseButtonImg from "../../images/CloseButtonImg.svg";
import DownloadButtonImg from "../../images/Download.svg";
import Colors from "../../constants/colors";
import formatDate from "../../utils/DateFunction";
import moment from "moment";
import { da } from "date-fns/locale";
import { API_URLS } from "../../utils/Constant";

// Initial state for useReducer
const initialState = {
  msgStatus: null,
  isModalOpen: false,
  msgColor: null,
  bgColor: "",
  msg: "",
};

// Action types for useReducer
const actionTypes = {
  SET_MSG_STATUS: "SET_MSG_STATUS",
  SET_IS_MODAL_OPEN: "SET_IS_MODAL_OPEN",
  SET_MSG_COLOR: "SET_MSG_COLOR",
  SET_BG_COLOR: "SET_BG_COLOR",
  SET_MSG: "SET_MSG",
};
const baseURL = process.env.REACT_APP_BASE_URL;
// Reducer function for useReducer
const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_MSG_STATUS:
      return { ...state, msgStatus: action.payload };
    case actionTypes.SET_IS_MODAL_OPEN:
      return { ...state, isModalOpen: action.payload };
    case actionTypes.SET_MSG_COLOR:
      return { ...state, msgColor: action.payload };
    case actionTypes.SET_BG_COLOR:
      return { ...state, bgColor: action.payload };
    case actionTypes.SET_MSG:
      return { ...state, msg: action.payload };
    default:
      return state;
  }
};

const TimelineItem = ({
  data,
  backgroundColor,
  closeModal: externalCloseModal,
  isOpen,
  onTimelineClick,
  customerName,
  DSCombArrPassedProp,
}) => {

  console.log('DSCombArrPassedProp in TimelineItem: ',DSCombArrPassedProp);

  // State management using useReducer
  const [state, dispatch] = useReducer(reducer, initialState);
  const { msgStatus, isModalOpen, msgColor, bgColor, msg } = state;
  console.log(state,'state')
  
  // Code below was previously present and was removed by mistake
  // const ActualDate = formatDate(
    //   data?.deliveredTimestamp != null ? data?.deliveredTimestamp : " "
    // );
    
  // Code below was previously present and was removed by mistake
  useEffect(() => {
    if (!isOpen) {
      dispatch({ type: actionTypes.SET_IS_MODAL_OPEN, payload: false });
    }
  }, [isOpen]);

  useEffect(() => {
    dispatch({ type: actionTypes.SET_IS_MODAL_OPEN, payload: isOpen });
  }, [isOpen]);

  const ActualDate = moment
    .utc(data?.deliveredTimestamp)
    .format("MMM D, h:mm a");

  const rightModalOpen = () => {
    if (isOpen) {
      dispatch({ type: actionTypes.SET_IS_MODAL_OPEN, payload: false });
      externalCloseModal();
    } else {
      dispatch({ type: actionTypes.SET_IS_MODAL_OPEN, payload: true });
      externalCloseModal();
      onTimelineClick();
    }
  };
  const closeModal = () => {
    dispatch({ type: actionTypes.SET_IS_MODAL_OPEN, payload: false });
    externalCloseModal();
  };

  const downloadData = () => {
    
    const body=data?.messageType ? data?.smsDescription : data?.email_body
    const timelineType=data?.messageType ? data?.messageType : data?.mail_id ?"EMAIL":"SMS"
    const deliveryStatus=data?.deliveryStatus
    const deliveredTimestamp=data?.deliveredTimestamp
    const communicationType=data?.communicationType
    const sourceSystem=data?.sourceSystem
    const vendorName=data?.vendorName
    const emailAddress=data?.email_address
    const mobileNumber=data?.mobileNumber
    const customerNames=customerName
    const campaignName=data?.campaign_name
    const templateId=data?.templateId
    const triggerSystem=data?.trigger_System
    const loanAccountNumber=data?.loanAccountNumber
    const messageCode=data?.messageCode

    const queryParam = new URLSearchParams();
    const dateString = deliveredTimestamp;
    const dateObject = new Date(dateString);

    const year = dateObject.getUTCFullYear();
    const month = (dateObject.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = dateObject.getUTCDate().toString().padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    if(body !=""){
      queryParam.set("body",body);
    }
    if (timelineType != "") {
      queryParam.set("timelineType",timelineType);
    }
    if (deliveryStatus !== "") {
      queryParam.set("deliveryStatus", deliveryStatus);
    }
    if (deliveredTimestamp !== "") {
      queryParam.set("deliveredTimestamp",formattedDate);
    }
    if (communicationType !== "") {
      queryParam.set("communicationType", communicationType);
    }
    if (sourceSystem !== "") {
      queryParam.set("sourceSystem", sourceSystem);
    }
    if (vendorName !== "") {
      queryParam.set("vendorName", vendorName);
    }
    if (emailAddress !== "") {
      queryParam.set("emailAddress", emailAddress);
    }
    if (mobileNumber !== "") {
      queryParam.set("mobileNumber", mobileNumber);
    }
    if (customerNames !== "") {
      queryParam.set("customerName",customerNames);
    }
    if (campaignName != "") {
      queryParam.set("campaignName", campaignName);
    }
    if (templateId !== "") {
      queryParam.set("templateId", templateId);
    }
    if (triggerSystem !== "") {
      queryParam.set("triggerSystem",triggerSystem);
    }
    if (loanAccountNumber != "") {
      queryParam.set("loanAccountNumber", loanAccountNumber);
    }
    if (messageCode != "") {
      queryParam.set("messageCode", messageCode);
    }
    let url = `${baseURL}${API_URLS.COMMUNICATION_TIMELINE.SINGLE_TIMELINE_DOWNLOAD}`;
    if (queryParam.toString() !== "") {
      url += `?${queryParam.toString()}`;
    }
    window.location.assign(url);

  };
  console.log(data,'state')

  useEffect(() => {
    console.log("DSCombArrPassedProp inside useEffect: ",DSCombArrPassedProp);
    
    if (DSCombArrPassedProp.DSFailArr.includes(data.deliveryStatus)) {
      dispatch({ type: actionTypes.SET_MSG_STATUS, payload: MessageNotDelivered });
      dispatch({ type: actionTypes.SET_MSG_COLOR, payload: Colors.lightRed });
      dispatch({ type: actionTypes.SET_MSG, payload: "Undelivered" });
    } else if (DSCombArrPassedProp.DSPassArr.includes(data.deliveryStatus)) {
      dispatch({ type: actionTypes.SET_MSG_STATUS, payload: MessageDelivered });
      dispatch({ type: actionTypes.SET_MSG_COLOR, payload: Colors.lightGreen });
      dispatch({ type: actionTypes.SET_MSG, payload: "Delivered" });
    }
    console.log("msgStatus: for each Item inside useEffect",msgStatus);

    if (data.messageType) {
      if (data.messageType === "SMS") {
        dispatch({ type: actionTypes.SET_BG_COLOR, payload: "#0655B4" });
      } else if (data.messageType === "Email") {
        dispatch({ type: actionTypes.SET_BG_COLOR, payload: "#BC7CD9" });
      }
    } else if (backgroundColor) {
      dispatch({ type: actionTypes.SET_BG_COLOR, payload: backgroundColor });
    }
  }, [msgStatus, data.deliveryStatus, data.messageType, backgroundColor]);
  console.log("msgStatus: for each Item outside useEffect",msgStatus);
  return (
    <>
      <div className="timeline-item">
        <div className="top_div">
          <time
            className="dates"
            onClick={rightModalOpen}
            style={{ background: bgColor }}
          >
            {ActualDate}
          </time>
          <span className="tag">
            <div className="tooltip-container">
              <img src={msgStatus} alt={msg} className="msg-status-img" />
              {console.log("msgStatus: ", msgStatus)}
              <div className="tooltip" style={{ backgroundColor: msgColor }}>
                {msg}
              </div>
            </div>
          </span>
        </div>
        <div className="tooltip-container">
          <div className="timeline-item-content">
            <p>{data?.campaign_name || "SMS"}</p>
            <span className="circle" />
          </div>
          <div className="tooltiptextmsg">
            {`${
              data?.email_body || data?.smsDescription || data?.emailContent
            }`}
          </div>
        </div>

        {isModalOpen && (
          <>
            <div className="modal_right">
              <div className="closeButton">
                <img onClick={closeModal} src={CloseButtonImg} alt="Close" />
              </div>
              <div className="campaingn_name">{data.campaign_name}</div>

              <div className="campaingn_Msgname">
                <div>
                  Template ID: {data.templateId ? data.templateId : " "}
                </div>
                <div className="downloadButtonMain_modal">
                  <div className="downloadText_modal">Download</div>
                  <div onClick={downloadData} className="downloadImg_modal">
                    <img
                      src={DownloadButtonImg}
                      alt="Download"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div className="last_msg">
                <pre className="greeting_msg">
                  {/* {data.emailContent ? (
                <a
                  href={data.emailContent}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {data.emailContent}
                </a>
              ) : data.email_body ? (
                <a
                  href={data.email_body}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {data.email_body}
                </a>
              ) : (
                `${data.smsDescription}`
              )} */}

                  {data.emailContent ? (
                    <a
                      href={data.emailContent}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {data.emailContent}
                    </a>
                  ) : data.email_body ? (
                    <div className="temp_div text-center">
                      <img
                        src={data?.email_body}
                        className="img-fluid"
                        alt="Temp Img"
                      />
                      {/* <a className="preview modal-dialog modal-dialog-centered">
                      Preview
                    </a> */}
                      <span
                        className="preview"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        Preview
                      </span>
                    </div>
                  ) : (
                    `${data.smsDescription}`
                  )}
                </pre>
              </div>
            </div>

            <div
              className="modal fade"
              id="exampleModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-div">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1
                      className="modal-title fs-5 ml-5"
                      id="exampleModalLabel"
                    >
                      Preview
                    </h1>
                    <span
                      className="btn-close close-button mr-5"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></span>
                  </div>
                  <div className="modal-body">
                    <img
                      src={data?.email_body}
                      className="img-fluid"
                      alt="Temp Img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default TimelineItem;
