export const API_URLS = {
  COMMUNICATION_TIMELINE: {
    USER_DETAILS: "/communication-timeline/v1/dashboard/getUserDetails",
    SMS_DETAILS: "/communication-timeline/v1/dashboard/getSmsDetails",
    EMAIL_DETAILS: "/communication-timeline/v1/dashboard/getEmailDetails",
    GET_DELIVERY_STATUSES: "communication-timeline/v1/dashboard/getDeliveryStatuses",
    All_DETAILS: "/communication-timeline/v1/dashboard/getAllTimeLine",
    TIMELINE_DASHBOARD: "/communication-timeline/v1/dashboard/getTimeline",
    COMMUNICATION_DROPDOWN:
      "/communication-timeline/v1/dashboard/typeOfCommunicationDropDown",
    SOURCE_DROPDOWN:
      "/communication-timeline/v1/dashboard/sourceMasterDropDown",
    VENDER_DROPDOWN:
      "/communication-timeline/v1/dashboard/vendorMasterDropDown",
    TIMELINE_DOWNLOAD:
      "/communication-timeline/v1/dashboard/allTimeline-download",
    SINGLE_TIMELINE_DOWNLOAD:
      "/communication-timeline/v1/dashboard/singleTimeline-download",
    DOWNLOAD:
      "/statistic-service/v1/statistics/download",
  },
  ROLE: {
    ROLE_LIST: "/auth-service/v1/roles/getRoles",
    LIST_PERMISSIONS: "/auth-service/v1/roles/getPermissions/",
    CREATE_ROLE: "/auth-service/v1/roles/createRole",
    UPDATE_ROLE: "/auth-service/v1/roles/updateRole",
    DELETE_ROLE: "/auth-service/v1/roles/deleteRole/",
  },
  USER: {
    USER_LIST: "/auth-service/v1/user/get-all-users/",
    CREATE_USER: "/auth-service/v1/user/save-user",
    DELETE_USER: "/auth-service/v1/user/delete-user/",
    UPDATE_USER: "/auth-service/v1/user/update-user",
  },
  STATISTICS:{
    GET_STATISTICS: '/statistic-service/v1/statistics/get-statistics',
    GET_GRAPH_DATA: '/statistic-service/v1/statistics/graph-data',
    GET_STATUS_WISE: '/statistic-service/v1/statistics/get-statuswise',
    GET_FUNCTION_WISE: '/communication-timeline/v1/dashboard/functionMasterDropDown',
  }
};

export const STATUS_CODE = {
  OK: 200,
  CREATED: 201,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  DATA_NOT_FOUND: 404,
  DUPLICATE_RECORD: 409,
  INTERNAL_SERVER_ERROR: 500,
  NOT_APPLICABLE: 406,
};
export const REGX = {
  CONTACTNUMBER: /^[6-9]\d{9}$/,
  EMAIL_REGX: /[A-Za-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$/,
  SPECIAL_CHAR: /^[a-zA-Z0-9_]+$/,
  COMMA: /^(?!,).*$/,
  PAN_CARD: /[A-Z]{5}[0-9]{4}[A-Z]{1}/,
  LOAN_REGX: /^[a-zA-Z0-9]+$/,
  NUMBER_ONLY: /^[0-9]{8}$/,
  NUMBERS_ONLY: /^[0-9]*$/,
  USER_NAME: /^[a-zA-Z_\s]{3,15}$/,
 ROLE_DESC: /^[^!@#$%^&*()<>":]+$/,
};
