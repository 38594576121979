import React from 'react';
import { Link } from 'react-router-dom';
import style from '../styles/CancelButton/cancelbutton.module.scss';
import { ROUTE_PATH } from '../constants/Routes'; // Make sure to import ROUTE_PATH

const CancelButton = ({ buttonName, onClick }) => {
  const routePath = buttonName === 'Add User' ? ROUTE_PATH.ROLE_USER_MANAGEMENT : ROUTE_PATH.ROLE_USER_MANAGEMENT;

  return (
    <Link to={routePath}>
      <button className={style.cancelbutton} onClick={onClick}>
        {buttonName}
      </button>
    </Link>
  );
};

export default CancelButton;