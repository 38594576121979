import { configureStore } from "@reduxjs/toolkit";
import loginreducer from "../Reducer/LoginReducer";
import sessionStorage from "redux-persist/es/storage/session";


import { persistStore, persistReducer } from 'redux-persist';
import { thunk } from 'redux-thunk';


const persistConfig = {
    key: 'root',
    storage:sessionStorage

  }
const persistedReducer = persistReducer(persistConfig, loginreducer)
export const Store=configureStore(
    {
        reducer: 
        {
        
            login:persistedReducer,
            middleware: [thunk]
        }
    }
)
export const persistor = persistStore(Store);
