import { useEffect, useState } from "react";
import CommonTable from "../../common/Table";
import EditButton from "../../common/Editbutton";
import DeleteButton from "../../common/DeleteButton";
import moment from "moment/moment";
import axiosRole from '../../utils/axiosRole';

import { API_URLS } from "../../utils/Constant";
import CommonModal from "../../common/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router";
import { ROUTE_PATH } from "../../constants/Routes";
import { useSelector } from "react-redux";
const RoleListing = () => {
const navigate =useNavigate();
  const [roleData, setRoleData] = useState([]);
  const [totalRows, setTotalRows] = useState(10);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [roleId, setRoleID] = useState(null);
  const selectLoginData = useSelector((state) => state?.login?.loginData);
  const permissionDTOList = selectLoginData?.permissionDTOList || [];
  const permission = permissionDTOList?.reduce((acc, permission) => {
    const { moduleId, ...rest } = permission;
    acc[moduleId] = rest;
    return acc;
  }, {});
  const Rolecolumns = [
    {
      name: "Role Name",
      selector: (row) => row.role_name,
      minWidth: "200px",
    },
    {
      name: "Role Description",
      selector: (row) => row.role_description,
      minWidth: "200px",
    },
    {
      name: "Created By",
      selector: (row) => row.createdBy,
      minWidth: "170px",
    },
    {
      name: "Created On",
      selector: (row) => row.createdAt,
      minWidth: "170px",
    }
  ];
  const actionColumn = {
    name: "Action",
    width: "90px",
    cell: (row) => (
      <div className="d-flex">
        {permission?.["2"]?.moduleEdit && (
          <EditButton
            onClick={() => {
              EditRole(row)
            }}
          />
        )}
        {permission?.["2"]?.moduleDelete && (
          <DeleteButton
            onClick={() => {
              DeleteRole(row?.id)
            }}
          />
        )}
      </div>
    ),
  };
  if(permission?.["2"]?.moduleEdit || permission?.["2"]?.moduleDelete ){
    Rolecolumns.push(actionColumn);
  }
  const EditRole=(data)=>{
    navigate(ROUTE_PATH.ROLE_USER_EDIT_ROLE,{state:{ROLE_DATA:data}})
  }
  const RoleListingData = (page) => {
    const queryParam = new URLSearchParams();
    queryParam.set("limit", perPage);
    queryParam.set("offset", page || 0);
    
    axiosRole.get(API_URLS.ROLE.ROLE_LIST, { params: queryParam })
      .then((res) => {
        let result = res?.data?.data;
       
        result = result.map((item) => {
          let newItem = {
            id: item.role_id,
            role_name: item.role_name,
            role_description: item.role_description,
            createdAt: moment(item.createdAt).format("YYYY-MM-DD"),
            createdBy: item.createdBy,
          };
          return newItem;
        });
        setTotalRows(res?.data?.totalCount);
        setRoleData([...result]);
      })
      .catch((error) => {
        console.error("Errorr============",error);
      });
  };
  const DeleteRole = (roleId) => {
    setShowConfirmationModal(true);
    setRoleID(roleId);
  };
  const handleDeleteRole = async (roleID) => {
    setShowConfirmationModal(false);
    axiosRole.delete(API_URLS.ROLE.DELETE_ROLE + roleId)
      .then((res) => {
        if (res.status === 200) {
          
          toast.info("Role Deleted SuccessFully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 500,
          });
        }
      })
      .catch((error) => {
        console.error("error", error?.response?.data?.statusMessage);
        toast.error(error?.response?.data?.statusMessage, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
      })
      .finally(() => {
        RoleListingData();
      });
  };
  useEffect(() => {
    RoleListingData();
  }, []);
  const handlePageChange = (page) => {
    RoleListingData((page - 1) * perPage);
  };
  return (
    <div className="">
      <CommonTable
        columns={Rolecolumns}
        data={roleData}
        loading={loading}
        perPage={perPage}
        totalRows={totalRows}
        handlePageChange={handlePageChange}
      />
      <CommonModal
        show={showConfirmationModal}
        handleClose={() => setShowConfirmationModal(false)}
        handleConfirm={() => {
          handleDeleteRole(roleId);
        }}
        message="are you sure you want to delete role ?"
      />
    </div>
  );
};
export default RoleListing;
