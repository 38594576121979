// Import necessary libraries and styles
import "react-datepicker/dist/react-datepicker.css";
import "../styles/customdatepicker/customdatepicker.scss";

import React, { useState } from "react";
import DatePicker from "react-datepicker";
import calendarIcon from "../images/Calender.svg";
import { format, addDays, differenceInCalendarMonths } from "date-fns";

const CustomDatePickerRange = ({ onDateRangeChange }) => {
  const [dateRange, setDateRange] = useState([null, null]);

  const handleChange = (update) => {
    /* const [startDate, endDate] = update;

    // This Calculates the minimum selectable date (3 months ago from the end date)
    const minSelectableDate = addDays(endDate, -90);

    // If the difference between the start and end dates is less than 3 months, this adjusts the start date
    if (endDate && differenceInCalendarMonths(endDate, startDate) < 3) {
      update = [minSelectableDate, endDate];
    }
 */
    setDateRange(update);

    if (update[0] && update[1]) {
      const startDateFormatted = format(
        update[0],
        "yyyy-MM-dd" // Change the date format here
      );
      const endDateFormatted = format(update[1], "yyyy-MM-dd"); // Change the date format here
      onDateRangeChange(startDateFormatted, endDateFormatted);
    }
  };

  const today = new Date();
  const maxSelectableDate = addDays(today, -1);

  const clearDateRange = () => {
    setDateRange([null, null]);
    onDateRangeChange(null, null);
  };

  return (
    <div className="div_wrapper">
      <DatePicker
        selectsRange={true}
        startDate={dateRange[0]}
        endDate={dateRange[1]}
        onChange={handleChange}
        isClearable={false}
        maxDate={maxSelectableDate}
        customInput={
          <div className="custom-date-input">
            <input
              style={{ width: "100%", height: "40px" ,fontSize:"13px"}}
              placeholder="MM-DD-YYYY - MM-DD-YYYY"
              value={
                dateRange[0] && dateRange[1]
                  ? `${dateRange[0].toLocaleDateString()} - ${dateRange[1].toLocaleDateString()}`
                  : ""
              }
              readOnly
            />
            <img
              src={calendarIcon}
              alt="calendarIcon"
              className="calendar_icon"
            />
          </div>
        }
      />
      <div className="clear_filter pt-1" onClick={clearDateRange}>
        Clear
      </div>
    </div>
  );
};

export default CustomDatePickerRange;
